import { TextField, Typography } from '@mui/material';
import React from 'react';
import { StyledInputControl } from './index.style';

const InputControl = ({ label, className = '', ...rest }) => (
  <StyledInputControl className={className}>
    {label && <Typography>{label}</Typography>}
    <TextField {...rest} />
  </StyledInputControl>
);

export { InputControl };
