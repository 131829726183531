const { styled } = require('@mui/material');

export const StyledPrintOrder = styled('div')`
  @media print {
    page-break-after: always;
  }
  margin-top: 4px;

  .footer-print {
    width: 100%;
    padding: 0 30px;
    height: 80px;

    p {
      float: right;
      font-size: 12px;
    }
  }
`;

export const StyledHeader = styled('div')`
  .header-print {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 24px;
    align-items: center;

    .logo-invoice {
      img {
        width: 140px;
      }
    }

    .address {
      margin-top: 10px;

      h4 {
        margin-top: 0;
        text-align: center;
        font-weight: 800;
        margin-bottom: 2px;
      }

      p {
        margin-top: 0;
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 2px;
        text-align: center;
      }
    }
  }

  .divider {
    width: 80%;
  }

  .title-print {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  .customer-info {
    display: grid;
    grid-template-columns: auto auto;
    gap: 3px;
    padding: 10px 70px;
    font-size: 12px;

    .float-right {
      width: 100px;
    }
  }
`;

export const StyledTestResult = styled('div')`
  padding: 0 25px;

  .glass-info {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .border {
    border: 1px solid gray;
    padding: 1px 3px;
    font-size: 12px;
  }

  .header {
    font-weight: 600;
  }

  .prescription-title {
    font-weight: 600;
    text-decoration: underline;
    margin-top: 3px;
    font-size: 12px;
  }

  .text-col {
    display: flex;
    gap: 12px;
    margin: 6px 0;
    font-size: 12px;

    .diagnose-item {
      margin: 1px 0;
      font-style: italic;
      span {
        margin-left: 6px;
      }
    }
  }

  .note-result {
    width: 100%;
    text-align: center;
    ont-size: 13px;
    font-weight: lighter;
    font-style: italic;
    font-size: 10px;
  }

  .order-note {
    margin-top: 16px;
    font-size: 12px;
  }
`;

export const StyledInvoicePrint = styled('div')`
  padding: 6px 25px;

  .border {
    border: 1px solid gray;
    padding: 3px 3px;
    font-size: 12px;
  }

  .header {
    font-weight: 600;
  }

  .total-payment {
    width: 100%;
    text-align: right;
    font-weight: 600;
    margin: 10px 0;
    font-size: 12px;

    p {
      margin: 2px;
    }
  }
`;

export const StyledPrintDialog = styled('div')`
  padding: 16px;

  .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
  }
`;
