import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import apis from '@src/apis';
import { debounce } from '@src/utils';
import { ProcessHandler, StatusCard, productTypeCards } from '@src/components';

import { StyledSearchProduct } from './index.style';

const SearchProduct = ({ note, setNote, onPick }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const handlePickProduct = (prod) => () => {
    onPick(prod);
  };

  const fetchProducts = async () => {
    setLoading(true);
    const res = await apis.products.getProducts({
      limit: 6,
      page: 1,
      status: true,
      search,
      searchFields: 'name',
    });
    setLoading(false);

    if (!res?.status) return;

    setProducts(res.result.products);
  };

  const renderPrimaryText = (prod) => {
    const { type, name } = prod || {};
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '3px 0',
          gap: '6px',
        }}
      >
        <StatusCard {...productTypeCards[type]} />
        <Typography>{`${name}`}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    debounce(fetchProducts, 500)();
  }, [search]);

  return (
    <StyledSearchProduct>
      <TextField
        placeholder="Tìm kiếm tên kính, thuốc, dịch vụ,..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <List className="product-list">
        <ProcessHandler loading={loading} align="center" mt="30px">
          {products.length &&
            products.map((prod) => (
              <ListItem
                key={prod.id}
                disablePadding
                className="medicine-item"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="add-icon"
                    onClick={handlePickProduct(prod)}
                  >
                    <AddIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={renderPrimaryText(prod)}
                  secondary={`SL: ${prod.quantity}, CK: ${prod.discount || 0}%`}
                />
              </ListItem>
            ))}
        </ProcessHandler>
      </List>
      <TextField
        multiline
        rows={2}
        placeholder="Ghi chú đơn hàng..."
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </StyledSearchProduct>
  );
};

export default SearchProduct;
