export const PAGINATION_LIMIT = 10;

export const TEST_QUEUE_IN_PAGE = {
  RECEPTIONIST: 1,
  TESTING: 2,
};

export const PRODUCT_TYPE = {
  ALL: 'ALL',
  SERVICE: 'SERVICE',
  MEDICINE: 'MEDICINE',
  GLASSES: 'GLASSES',
  OTHER: 'OTHER',
};

export const TESTING_STEP = {
  NEW: 0, // new user
  WAITING: 1, //  in test queue
  TEST: 2, // in testing screen ( submit testing with indicator, reason)
  DIAGNOSE: 3, // in diagnose screen (concluding, give a prescription)
  GLASS_MEDICINE_SERVICE: 4, // add service, glass, medicines
  /**
   * If customer is in CONFIRM_ORDER
   * can not update testing,
   * can not create new order from inprogress testing
   */
  CONFIRM_ORDER: 5,
  CANCELED: 6,
  DONE: 7,
  CANCEL_FROM_QUEUE: 8, // used to in test queue, but it was removed before add to testing
};

export const ORDER_STATUS = {
  All: 'ALL',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  PAID: 'PAID',
};

export const TESTING_STATUS = {
  INPROGRESS: 'INPROGRESS',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
};

export const GENDER = {
  MALE: 'Nam',
  FEMALE: 'Nữ',
};

export const RETEST_DATE = {
  All: 'ALL',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  DONE: 'DONE',
};

export const RETEST_DATE_TYPE = {
  SELECT: 'SELECT',
  DATEPICKER: 'DATEPICKER',
};

export const RETEST_DATE_OPTIONS = [
  { value: 0, label: 'Chọn ngày' },
  { value: 7, label: '1 tuần' },
  { value: 14, label: '2 tuần' },
  { value: 21, label: '3 tuần' },
  { value: 30, label: '1 tháng' },
  { value: 60, label: '2 tháng' },
  { value: 90, label: '3 tháng' },
  { value: 180, label: '6 tháng' },
];

export const ACCESS_TOKEN_KEY = 'accessToken';
