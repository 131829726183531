const { styled } = require('@mui/material');

export const StyledRetestDate = styled('div')`
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0;

    .input-date {
      margin-right: 12px;
      align-self: end;
    }

    .filter-dateRange {
      margin-top: 23px;
    }

    .option-filter-date {
      display: flex;

      .filter-status {
        min-width: 134px;
      }
    }
  }

  .filter-search {
    .input-search {
      width: 250px !important;
      margin-top: 24px;
    }
  }
`;

export const StyledRetestDateDialog = styled('div')`
  .list-item {
    display: flex;
    align-items: center;
  }

  .control-item {
    display: flex;
    justify-content: space-evenly;

    .status-reTestDate {
      width: 180px;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      margin-top: 27px;
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    margin: 0 6% 18px 0;

    button {
      margin-left: 12px;
    }
  }
`;
