import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  convertStatisticDataToChartData,
  getRandomColor,
} from '@src/utils/chart';
import { CHART_COLOR } from '@src/styles/color';
import { StyledBarChartWrapper } from './index.style';

ChartJS.register(...registerables, ChartDataLabels);

/**
 * example:
 * data: [{
        day: '06-04-2023',
        totalBenefit: 1229000,
        totalRevenue: 1454000,
      },
      {
        day: '08-04-2023',
        totalBenefit: 1981000,
        totalRevenue: 3449000,
      },]

    {
      06-04-2023: { totalBenefit: 10, totalRevenue: 20},
      08-04-2023: { totalBenefit: 10, totalRevenue: 20},
    }
 */
const BarChart = ({ data, title }) => {
  const { labels, datasets } = convertStatisticDataToChartData(data);
  const chartData = {
    labels,
    datasets: datasets.map((item) => {
      const color = CHART_COLOR[item.label] || getRandomColor();
      const datasetItem = {
        ...item,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        datalabels: {
          display: false,
        },
        barPercentage: 0.9,
      };

      return datasetItem;
    }),
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`,
        },
      },
      legend: {
        display: true,
        position: 'top',
        padding: 5,
        labels: {
          usePointStyle: true,
          boxWidth: 7,
          pointStyle: 'rect',
        },
      },
      title: {
        display: true,
        position: 'top',
        text: title,
        font: {
          size: 14,
        },
      },
    },
  };

  return (
    <StyledBarChartWrapper>
      <Bar data={chartData} options={options} />
    </StyledBarChartWrapper>
  );
};

export default BarChart;
