import { styled } from '@mui/material/styles';

import { COLOR } from '@src/styles/color';

export const StyledConfigTheme = styled('div')`
  .button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledSelectColorSet = styled('div')`
  .list-color-select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8px;
  }
`;

export const StyledItemColorSetSelect = styled('span')`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 20px 32px 0;
  cursor: pointer;
  border: ${({ active }) => (active ? `3px solid ${COLOR.lightText}` : 'none')};
`;
