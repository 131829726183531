import React from 'react';
import { StyledCard, StyleTitle } from './index.style';

export function Card({ children, title }) {
  return (
    <StyledCard>
      {title && <StyleTitle>{title}</StyleTitle>}
      {children}
    </StyledCard>
  );
}
