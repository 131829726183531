import React from 'react';
import { Box, Typography } from '@mui/material';
import noDataImage from '@src/assets/images/no-data.png';
import { StyledNoData } from './index.style';

const NoData = () => (
  <Box width="100%" margin="auto">
    <StyledNoData>
      <img src={noDataImage} alt="no data" />
      <Typography>Không có dữ liệu</Typography>
    </StyledNoData>
  </Box>
);

export default NoData;
