import { PAGINATION_LIMIT } from '@src/constants';
import api from './api';

/**
 *
 * @param {*} products: [{ productId, quantity, discount }]
 * @returns response
 */

const getOrders = async ({
  limit = PAGINATION_LIMIT,
  page = 1,
  search = '',
  sort = 'createdAt_desc',
  fields,
  searchFields,
  status,
  startDate,
  endDate,
}) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/orders',
      params: {
        limit,
        offset: (page - 1) * PAGINATION_LIMIT,
        search,
        fields,
        sort,
        searchFields,
        status,
        startDate,
        endDate,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const createOrder = async ({ testingId, products, note, discount }) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/orders',
      data: { testingId, products, note, discount },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const getOrderById = async (orderId) => {
  try {
    const res = await api({
      method: 'GET',
      url: `/orders/${orderId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const getOrderByCustomerId = async (
  customerId,
  {
    limit = PAGINATION_LIMIT,
    page = 1,
    search = '',
    sort = 'createdAt_desc',
    fields,
    searchFields,
    status,
  },
) => {
  try {
    const res = await api({
      method: 'GET',
      url: `/orders/customers/${customerId}`,
      params: {
        limit,
        offset: (page - 1) * PAGINATION_LIMIT,
        search,
        fields,
        sort,
        searchFields,
        status,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const getOrderByTestingId = async (testingId) => {
  try {
    const res = await api({
      method: 'GET',
      url: `/orders/testings/${testingId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const confirmOrder = async (orderId) => {
  try {
    const res = await api({
      method: 'POST',
      url: `/orders/confirm/${orderId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const cancelOrder = async (orderId) => {
  try {
    const res = await api({
      method: 'POST',
      url: `/orders/cancel/${orderId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

/**
 *
 * @param {*} products: [{ productId, quantity, discount }]
 * @returns response
 */
const updateOrder = async ({ orderId, products, note, discount }) => {
  try {
    const res = await api({
      method: 'put',
      url: `/orders/${orderId}`,
      data: { products, note, discount },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const forceUpdateOrder = async ({
  orderId,
  products,
  note,
  discount,
  status,
}) => {
  try {
    const res = await api({
      method: 'put',
      url: `/orders/${orderId}/force`,
      data: { products, note, discount, status },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const deleteOrder = async (orderId, revokeQuantity = false) => {
  try {
    const res = await api({
      method: 'DELETE',
      url: `/orders/${orderId}`,
      data: { revokeQuantity },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export {
  createOrder,
  getOrderById,
  getOrderByTestingId,
  confirmOrder,
  cancelOrder,
  updateOrder,
  getOrders,
  getOrderByCustomerId,
  deleteOrder,
  forceUpdateOrder,
};
