import React, { useEffect, useState } from 'react';
import { BaseDialog, ProcessHandler } from '@src/components';
import { Button, Grid } from '@mui/material';
import ReTestDatePicker from '@src/components/ReTestDatePicker';
import SelectOption from '@src/components/SelectOption/SelectOption';
import { RETEST_DATE, RETEST_DATE_TYPE } from '@src/constants';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import apis from '@src/apis';

import { StyledRetestDateDialog } from './index.style';

const reTestDateStatus = {
  key: 'status-reTestDate',
  title: 'Trạng thái',
  type: 'select-options',
  name: 'status-reTestDate',
  options: [
    { value: RETEST_DATE.DONE, label: 'Hoàn thành' },
    { value: RETEST_DATE.CANCELLED, label: 'Hủy' },
    { value: RETEST_DATE.PENDING, label: 'Đang xử lý' },
  ],
};

const initialReTestDate = {
  date: new Date(),
  type: RETEST_DATE_TYPE.SELECT,
  optionValue: 0,
  status: RETEST_DATE.PENDING,
};

const ReTestDateDialog = ({
  open,
  reTestDate,
  onUpdateReTestDate,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [reTestDateObj, setReTestDateObj] = useState(initialReTestDate);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleStatusReTestDate = (name, value) => {
    setReTestDateObj((prev) => ({ ...prev, status: value }));
  };

  const handleTypeReTestDate = (value) => {
    setReTestDateObj((prev) => ({ ...prev, type: value }));
  };

  const handleDateChange = (date, optionValue) => {
    setReTestDateObj((prev) => ({ ...prev, date, optionValue }));
  };

  const handleSubmitUpdate = async () => {
    setLoading(true);
    const res = await apis.retestDates.updateReTestDate({
      reTestDateId: reTestDateObj.id,
      ...reTestDateObj,
    });
    setLoading(false);

    if (!res || !res.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: res?.code || 0,
          message: res?.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'Cập nhật lịch tái khám thành công',
      }),
    );
    setReTestDateObj(initialReTestDate);
    onUpdateReTestDate(res.result);
    onClose();
  };

  useEffect(() => {
    if (!reTestDate) return;
    setReTestDateObj(reTestDate);
  }, [reTestDate]);

  return (
    <BaseDialog
      title="Cập nhật ngày tái khám"
      open={open}
      onClose={handleClose}
    >
      <StyledRetestDateDialog>
        <Grid container spacing={4} className="list-item">
          <Grid item xs={6}>
            <div className="control-item">
              <ReTestDatePicker
                type={reTestDateObj.type}
                dateValue={reTestDateObj.date}
                optionValue={reTestDateObj.optionValue}
                onTypeChange={handleTypeReTestDate}
                onDateChange={handleDateChange}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="control-item">
              <SelectOption
                key={reTestDateStatus.key}
                className="status-reTestDate"
                defaultValue={reTestDateObj.status}
                title={reTestDateStatus.title}
                name={reTestDateStatus.name}
                options={reTestDateStatus.options}
                onChange={handleStatusReTestDate}
              />
            </div>
          </Grid>
        </Grid>
        <div className="button-group">
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmitUpdate}
          >
            <ProcessHandler loading={loading}>Cập nhật</ProcessHandler>
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Hủy
          </Button>
        </div>
      </StyledRetestDateDialog>
    </BaseDialog>
  );
};

export default ReTestDateDialog;
