import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import apis from '@src/apis';
import { debounce } from '@src/utils';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { StyledAutoComplete } from './index.style';

const SEARCH_ROW_LIMIT = 5;

const filterOptions = createFilterOptions({
  stringify: ({ name, phoneNumber }) => `${name} ${phoneNumber}`,
});

/**
 * Search customer with AutoComplete component
 * @param {*} customer: active customer object
 * @param {*} error: the help text when you want to show error at the bottom of input: example: customerRequired
 * @param {*} xs: custom style
 * @param {*} onChange: triggers event when customer be changed
 */
const CustomerSearch = ({
  customer,
  className,
  error,
  onChange,
  onCreateNewUser,
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const getDisplayText = (user) => {
    if (!user) return '';
    return user.name;
  };

  const fetchUsers = async () => {
    if (!search) {
      setUsers([]);
      return;
    }

    // When user be selected, search will be set to value of getDisplayText
    // then do not fetchData when match this condition
    const displayText = getDisplayText(customer);
    if (displayText === search && users) return;

    setLoading(true);
    const data = await apis.customers.getCustomers({
      searchFields: 'name,phoneNumber',
      search,
      limit: SEARCH_ROW_LIMIT,
    });
    setLoading(false);
    if (data?.status) {
      setUsers(data.result.customers);
    }
  };

  const handleChangeInput = (event) => {
    setSearch(event.target.value);
  };

  const handleCloseList = () => {
    setOpen(false);
    inputRef.current?.blur();
    if (customer) {
      const displayText = getDisplayText(customer);
      if (displayText === search) return;
      onChange(null);
    }
  };

  const handleFocusInput = () => {
    setOpen(true);
  };

  const handleBlurInput = () => {
    handleCloseList();
  };

  const handleSelectUser = (user) => {
    setOpen(false);
    setSearch('');
    onChange(user);
  };

  const handleAddNewCustomer = () => {
    if (search) onCreateNewUser(search);
  };

  useEffect(() => {
    debounce(fetchUsers, 500)(search);
  }, [search]);

  return (
    <StyledAutoComplete className={className}>
      <Autocomplete
        id="customer-input"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        options={users}
        loading={loading}
        value={customer}
        size="small"
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name="customer-input"
            fullWidth
            value={search}
            inputRef={inputRef}
            placeholder="Nhập số điện thoại hoặc tên..."
            onChange={handleChangeInput}
            onBlur={handleBlurInput}
            onFocus={handleFocusInput}
            focused={open}
            helperText={t(error)}
            error={!!error}
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null,
            }}
          />
        )}
        getOptionLabel={(option) => getDisplayText(option)}
        renderOption={(props, option) => (
          <ListItemButton
            key={option.id}
            onClick={() => handleSelectUser(option)}
          >
            <ListItemText
              primary={getDisplayText(option)}
              secondary={<span>SDT: {option.phoneNumber}</span>}
            />
          </ListItemButton>
        )}
        noOptionsText={
          <Box>
            {search ? (
              <Button ref={buttonRef} onClick={handleAddNewCustomer}>
                Tạo mới
              </Button>
            ) : (
              'Không có kết quả nào!'
            )}
          </Box>
        }
      />
    </StyledAutoComplete>
  );
};

export default CustomerSearch;
