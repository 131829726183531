import apis from '@src/apis';
import actions from '@src/redux/actions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TESTING_STEP } from '@src/constants';

export const mapPrescription = (medicines = []) =>
  medicines.map((item) => {
    const { name, quantity, instruction } = item;
    return { name, quantity, instruction, productId: item.id };
  });

const useCustomerTesting = () => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState();

  const [testing, setTesting] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [order, setOrder] = useState();
  const [reTestDate, setReTestDate] = useState();

  const createTesting = async ({ customerId, reason, indicators, draft }) => {
    setLoading(true);
    const res = await apis.testings.pickCustomerForTest({
      customerId,
      reason,
      indicators: JSON.stringify(indicators),
      draft,
    });
    setLoading(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return false;
    }
    dispatch(
      actions.noti.push({
        message: 'Tạo đơn khám thành công',
        severity: 'success',
      }),
    );
    if (draft) return true;

    setTesting(res.result);
    setActiveStep(res.result?.customer?.testingStep);
    return true;
  };

  const fetchOrderByTesting = async (testingId) => {
    setFetchLoading(true);
    const resOrder = await apis.orders.getOrderByTestingId(testingId);
    setFetchLoading(false);
    if (!resOrder?.status) return;

    setOrder(resOrder.result);
  };

  const fetchReTestDateByTestingId = async (testingId) => {
    if (!testingId) return;

    const res = await apis.retestDates.getReTestDateByTestingId(testingId);

    if (!res.result) {
      return;
    }
    setReTestDate(res.result);
  };

  const fetchInprogressTesting = async (customerId) => {
    setFetchLoading(true);
    const res = await apis.testings.getInprogressTesting(customerId);
    await fetchReTestDateByTestingId(res?.result?.id);
    setFetchLoading(false);
    if (!res?.status || !res?.result?.customer) {
      setTesting(null);
      setActiveStep(null);
      return;
    }

    const tmpTesting = res.result;
    const tmpTestingStep = tmpTesting?.customer?.testingStep;

    if (
      tmpTestingStep === TESTING_STEP.GLASS_MEDICINE_SERVICE ||
      tmpTestingStep === TESTING_STEP.CONFIRM_ORDER
    )
      fetchOrderByTesting(tmpTesting?.id);
    else setOrder();

    setTesting(tmpTesting);
    setActiveStep(tmpTestingStep);
  };

  const validatePrescription = (prescription = []) => {
    const emptyInstructionNames = prescription.reduce((prev, curr) => {
      if (!curr.instruction) {
        return [...prev, curr.name];
      }
      return prev;
    }, []);

    return emptyInstructionNames.join(',');
  };

  const writeDiagnoseToTesting = async (medicines, diagnose, note) => {
    const prescription = mapPrescription(medicines);
    const errMsg = validatePrescription(prescription) || '';
    if (errMsg) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: `${errMsg} đang chưa được điền chỉ dẫn!`,
        }),
      );
      return;
    }
    setUpdateLoading(true);
    const res = await apis.testings.updateTesting({
      testingId: testing.id,
      prescription,
      note,
      diagnose,
    });
    setUpdateLoading(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Tạo kết luận khám thành công',
        severity: 'success',
      }),
    );
    fetchOrderByTesting(res.result?.id);

    setTesting(res.result);
    setActiveStep(res.result?.customer?.testingStep);
  };

  const updateTesting = async (reason, indicators, draft) => {
    if (!reason) {
      dispatch(
        actions.noti.push({
          message: 'Chưa điền lý do khám, chưa thể lưu thông tin!',
          severity: 'warning',
        }),
      );
      return;
    }

    setUpdateLoading(true);
    const res = await apis.testings.updateTesting({
      testingId: testing.id,
      reason,
      indicators: JSON.stringify(indicators),
      draft,
    });
    setUpdateLoading(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Cập nhật đơn khám thành công',
        severity: 'success',
      }),
    );

    if (draft) return;

    setTesting(res.result);
    setActiveStep(res.result?.customer?.testingStep);
  };

  return {
    testing,
    order,
    reTestDate,
    loading,
    fetchLoading,
    updateLoading,
    activeStep,
    setActiveStep,
    setTesting,
    setOrder,
    setReTestDate,
    createTesting,
    fetchInprogressTesting,
    fetchOrderByTesting,
    writeDiagnoseToTesting,
    updateTesting,
  };
};
export default useCustomerTesting;
