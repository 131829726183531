import React from 'react';
import { Typography } from '@mui/material';
import { delimitNumber } from '@src/utils/number';
import { StyledStatsCard } from './index.style';

/**
 * data: { total, totalRevenue, totalBenefit}
 */
const StatsCard = ({ title, data = {}, icon, ...rest }) => (
  <StyledStatsCard {...rest}>
    <div className="main-stats">
      <div className="stats-text">
        <Typography className="stats-title">{title}</Typography>
        <Typography className="stats-number">
          Tổng đơn: {delimitNumber(data.total)}
        </Typography>
        {data.totalRevenue ? (
          <Typography className="stats-number">
            Doanh thu: {delimitNumber(data.totalRevenue)}
          </Typography>
        ) : (
          ''
        )}
        {data.totalBenefit ? (
          <Typography className="stats-number">
            Lợi nhuận: {delimitNumber(data.totalBenefit)}
          </Typography>
        ) : (
          ''
        )}
      </div>
      <div className="stats-icon">
        <img src={icon} alt="stats-icon" />
      </div>
    </div>
  </StyledStatsCard>
);

export default StatsCard;
