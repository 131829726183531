import React, { useEffect, useRef, useState } from 'react';
import {
  ConfirmDialog,
  CustomTable,
  PageFrame,
  ProcessHandler,
  TestQueue,
} from '@src/components';
import actions from '@src/redux/actions';
import { useDispatch } from 'react-redux';
import CustomerSearch from '@src/components/CustomerSearch';
import {
  Button,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material';
import { Add, Delete, Edit, Visibility } from '@mui/icons-material';
import apis from '@src/apis';
import { GENDER } from '@src/constants';
import { calculateAge, convertFormatDate, debounce } from '@src/utils';
import { Link } from 'react-router-dom';
import CreateCustomerDialog from './CreateCustomerDialog';
import { StyledReceptionist } from './index.style';

const Receptionist = () => {
  const dispatch = useDispatch();

  const isSearchChangeRef = useRef(false);

  const [customers, setCustomers] = useState([]);
  const [testQueues, setTestQueues] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [customerUpdate, setCustomerUpdate] = useState(null);
  const [creationFromSearch, setCreationFromSearch] = useState('');
  const [loadingCreateTQ, setLoadingCreateTQ] = useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [firstLoadComponent, setFirstLoadComponent] = useState(true);

  const handleCloseUpdateDialog = () => {
    setCustomerUpdate(null);
  };

  const handleOpenUpdateDialog = (customer) => {
    setCustomerUpdate(customer);
  };

  const fetchCustomers = async () => {
    if (isSearchChangeRef.current) {
      setLoadingSearch(true);
    }

    const res = await apis.customers.getCustomers({
      page: paging.page,
      searchFields: 'name,phoneNumber',
      search: searchValue,
    });
    setLoadingSearch(false);
    if (!res?.status) return;

    setCustomers(res.result.customers);
    setPaging({ ...paging, total: res.result.total });
  };

  const inputSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const createTestQueue = async (customerId) => {
    setLoadingCreateTQ(true);
    const res = await apis.testQueues.createTestQueue(customerId);
    setLoadingCreateTQ(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Thêm khách vào hàng đợi thành công',
        severity: 'success',
      }),
    );

    setTestQueues((prev) => [...prev, res.result]);
  };

  const handleDeleteCustomer = async () => {
    const res = await apis.customers.removeCustomer(deleteCustomerId);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Xóa khách hàng thành công',
        severity: 'success',
      }),
    );

    setCustomers((prev) =>
      prev.filter((customer) => customer.id !== deleteCustomerId),
    );
    setDeleteCustomerId('');
  };

  const handleChangeCustomerSearch = (userData) => {
    createTestQueue(userData.id);
  };

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const handleCloseDialog = () => {
    setCreationFromSearch('');
    setOpenCreateDialog(false);
  };

  const handleOpenCreateDialog = () => {
    setCreationFromSearch('');
    setOpenCreateDialog(true);
  };

  const handleCreateNewCustomer = (text) => {
    setCreationFromSearch(text);
    setOpenCreateDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteCustomerId('');
  };

  const handleCreateNewCustomerDialog = (newCustomerId) => {
    fetchCustomers();
    setOpenCreateDialog(false);
    createTestQueue(newCustomerId);
  };

  const handleUpdatedCustomer = (updatedCustomer) => {
    setCustomerUpdate();
    const tmpCustomers = customers.map((item) => {
      if (item.id === updatedCustomer.id) return updatedCustomer;
      return item;
    });

    setCustomers(tmpCustomers);
  };

  const columns = [
    {
      field: 'name',
      align: 'left',
      id: 'name',
      title: 'Tên khách',
    },
    {
      field: 'gender',
      align: 'left',
      id: 'gender',
      title: 'Giới tính',
      render: (customer) => GENDER[customer.gender],
    },
    {
      field: 'birthday',
      align: 'left',
      id: 'birthday',
      title: 'Ngày sinh',
      render: (customer) => convertFormatDate(customer.birthday, 'DD-MM-YYYY'),
    },
    {
      field: 'age',
      align: 'left',
      id: 'age',
      title: 'Tuổi',
      render: (customer) => calculateAge(customer.birthday),
    },
    {
      field: 'phoneNumber',
      align: 'left',
      id: 'phoneNumber',
      title: 'Số điện thoại',
      render: (customer) => customer.phoneNumber || 'Không có',
    },
    {
      field: 'address',
      align: 'center',
      id: 'address',
      title: 'Địa chỉ',
    },
    {
      id: 'actions',
      title: 'Hành động',
      render: (customer) => (
        <>
          <Link to={`khach-hang/${customer.id}`}>
            <Tooltip title="Thông tin chi tiết">
              <IconButton>
                <Visibility />
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip
            title="Chỉnh sửa"
            onClick={() => {
              handleOpenUpdateDialog(customer);
            }}
          >
            <IconButton>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Xóa">
            <IconButton
              onClick={() => {
                setDeleteCustomerId(customer.id);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Thêm vào hàng đợi khám">
            <IconButton
              onClick={() => {
                createTestQueue(customer.id);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    isSearchChangeRef.current = false;
    fetchCustomers();
  }, [paging.page]);

  useEffect(() => {
    if (!firstLoadComponent) {
      isSearchChangeRef.current = true;
      debounce(fetchCustomers, 500)(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    setFirstLoadComponent(false);
    isSearchChangeRef.current = false;
  }, []);

  return (
    <PageFrame title="Lễ Tân">
      <StyledReceptionist>
        <div className="search-wrapper">
          <CustomerSearch
            className="customer-search-wrapper"
            onCreateNewUser={handleCreateNewCustomer}
            onChange={handleChangeCustomerSearch}
          />
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenCreateDialog}
          >
            Tạo mới
          </Button>
          <ProcessHandler loading={loadingCreateTQ} />
        </div>
        <TestQueue queue={testQueues} setQueue={setTestQueues} />
        <Divider />
        <div className="customer-list">
          <div className="list-header">
            <Typography>Danh sách khách hàng</Typography>
            <div className="search-customer">
              <TextField
                className="input"
                size="small"
                placeholder="Tìm kiếm khách hàng"
                value={searchValue}
                onChange={inputSearchChange}
                InputProps={{
                  endAdornment: loadingSearch ? (
                    <ProcessHandler loading size={20} />
                  ) : null,
                }}
              />
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={customers}
            total={paging.total}
            page={paging.page}
            onChangePage={handleChangePage}
          />
        </div>
      </StyledReceptionist>
      <ConfirmDialog
        open={Boolean(deleteCustomerId)}
        title="Xóa khách hàng"
        description="Bạn có muốn xóa khách hàng này?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onCancel={handleCloseDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onOk={handleDeleteCustomer}
      />
      <CreateCustomerDialog
        open={openCreateDialog}
        fromSearch={creationFromSearch}
        onClose={handleCloseDialog}
        onCreateCustomer={handleCreateNewCustomerDialog}
      />
      <CreateCustomerDialog
        open={customerUpdate}
        customer={customerUpdate}
        onClose={handleCloseUpdateDialog}
        onUpdateCustomer={handleUpdatedCustomer}
      />
    </PageFrame>
  );
};

export default Receptionist;
