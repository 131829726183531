import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';

import { CHART_COLOR } from '@src/styles/color';
import { getRandomColor } from '@src/utils/chart';
import { StyledChartWrapper } from './index.style';

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

// data: { data1: 10, data2: 20, data3: 30 },
const DoughnutChart = ({ data, title }) => {
  const { t } = useTranslation();
  const chartData = {
    labels: Object.keys(data).map((key) => t(key)),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: Object.keys(data).map(
          (item) => CHART_COLOR[item] || getRandomColor(),
        ),
        borderColor: '#fff',
        borderWidth: 1,
        color: 'blue',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 0,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      title: {
        display: true,
        position: 'top',
        text: title,
        font: {
          size: 14,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 12,
        },
        formatter: (value, value2) => {
          const total = value2.dataset.data.reduce((acc, val) => acc + val);
          const percentage = (value / total) * 100;
          if (percentage < 5) return '';
          return `${Math.round(percentage * 100) / 100}%`;
        },
      },
    },
  };

  return (
    <StyledChartWrapper>
      <Doughnut data={chartData} options={options} width="200" height="200" />
    </StyledChartWrapper>
  );
};

export default DoughnutChart;
