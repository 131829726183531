const { styled } = require('@mui/material');

export const StyledOrder = styled('div')`
  .filter-orders {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-choose {
      display: flex;
    }

    .filter-dateRange {
      margin-top: 23px;
    }
  }

  .search {
    margin: 18px 0 28px 0;

    .input-search {
      width: 250px !important;
      margin-top: 24px;
    }
  }
`;

export const StyledDeleteOrder = styled('div')`
  padding: 12px;

  .btn-group {
    width: 100%;
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    margin-top: 6px;
  }
`;
