import { all, put, takeEvery } from 'redux-saga/effects';
import apis from '@src/apis';
import api from '@src/apis/api';
import actions from '../actions';

function* verifyTokenSaga({ accessToken }) {
  try {
    const { status, result } = yield apis.auth.verifyToken(accessToken);
    if (!status) throw new Error();

    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    yield put(actions.auth.verifyTokenSuccess(accessToken, result));
  } catch (error) {
    yield put(actions.auth.verifyTokenFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.auth.actionTypes.VERIFY_TOKEN, verifyTokenSaga),
  ]);
}
