import axios from 'axios';
import camelCase from 'camelcase-keys';
import { API_URL } from '@src/configs';
import { ERROR_CODE } from '@src/errors/code';

const axiosClient = axios.create({
  baseURL: `${API_URL}/api/v1`,
  responseType: 'json',
  timeout: 15 * 1000,
});

axiosClient.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => camelCase(response?.data, { deep: true }),
  (error) => {
    if (error?.response && error.response?.config?.method === 'get') {
      if (
        error.response.status === ERROR_CODE.UNAUTHORIZED ||
        error.response?.data?.code === ERROR_CODE.UNAUTHORIZED
      )
        window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
