import React, { useState } from 'react';
import { Card, InputControl, ProcessHandler } from '@src/components';
import { Button, Grid } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { StyledUpdateFacility } from './index.style';

const initialFacility = {
  name: '',
  address: '',
  email: '',
  phoneNumber: '',
  logo: '',
};

const validationSchema = yup.object({
  name: yup.string().optional(),
  address: yup.string().optional(),
  logo: yup.string().optional(),
  phoneNumber: yup
    .string()
    .optional()
    .matches(/^(84|0[3|5|7|8|9])([0-9]{8})\b/, 'Số điện thoại không hợp lệ'),
  email: yup
    .string()
    .optional()
    .matches(
      /^[^0-9][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[@][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,4}$/,
      'Email không hợp lệ',
    ),
});

const UpdateFacility = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (values, helpers) => {
    setLoading(true);
    // call API
    console.log(values, helpers);
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialFacility,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  return (
    <Card title="Cập nhật cơ sở">
      <StyledUpdateFacility>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="item-input">
              <InputControl
                label="Logo"
                size="small"
                placeholder="Nhập url logo"
                name="logo"
                className="input"
                type="text"
                value={formik.values.logo}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.logo)}
                helperText={formik.touched.logo && formik.errors.logo}
              />
            </div>
            <div className="item-input">
              <InputControl
                label="Tên cơ sở"
                size="small"
                placeholder="Nhập tên cơ sở"
                name="name"
                className="input"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="item-input">
              <InputControl
                label="Địa chỉ"
                size="small"
                placeholder="Nhập địa chỉ"
                name="address"
                className="input"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </div>
            <div className="item-input">
              <InputControl
                label="Email"
                size="small"
                placeholder="nhập email"
                name="email"
                className="input"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="item-input">
              <InputControl
                label="Số điện thoại"
                size="small"
                placeholder="Nhập số điện thoại"
                name="phoneNumber"
                className="input"
                type="text"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phoneNumber)}
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </div>
            <Button sx={{ float: 'right' }} type="submit" variant="contained">
              <ProcessHandler loading={loading} size={30} color="secondary">
                Cập nhật thay đổi
              </ProcessHandler>
            </Button>
          </Grid>
        </Grid>
      </StyledUpdateFacility>
    </Card>
  );
};

export default UpdateFacility;
