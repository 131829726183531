import React from 'react';
import ConfigTheme from '@src/components/ConfigTheme';
import { Grid } from '@mui/material';
import UpdateFacility from '@src/components/UpdateFacility';

const GeneralSettings = () => {
  console.log('General Settings');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <UpdateFacility />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigTheme />
      </Grid>
    </Grid>
  );
};

export default GeneralSettings;
