import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BaseDialog } from '../BaseDialog';

const ConfirmDialog = ({
  titleOk,
  titleCancel,
  description,
  onOk,
  onCancel,
  ...rest
}) => {
  const handleOk = () => {
    onOk();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <BaseDialog width="300px" {...rest}>
      <Box padding="12px">
        {description && <Typography margin="6px">{description}</Typography>}
        <Box display="flex" gap="6px" justifyContent="flex-end">
          <Button variant="outlined" size="small" onClick={handleCancel}>
            {titleCancel}
          </Button>
          <Button variant="contained" size="small" onClick={handleOk}>
            {titleOk}
          </Button>
        </Box>
      </Box>
    </BaseDialog>
  );
};

export { ConfirmDialog };
