const { styled } = require('@mui/material');

export const StyledReceptionist = styled('div')`
  padding: 12px 0;

  .search-customer {
    display: flex;
    justify-content: flex-end;
    margin: 8px 0;

    .input {
      width: 250px;
    }
  }

  .search-wrapper {
    max-width: 60%;
    display: flex;
    gap: 12px;
    width: 40%;
    margin-bottom: 20px;

    .customer-search-wrapper {
      flex-grow: 1;
    }
  }

  .customer-list {
    margin-top: 12px;

    .list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      p:first-of-type {
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
  }
`;

export const StyledCreateCustomer = styled('div')`
  padding: 12px;

  .control-item {
    margin: 12px 0;
    width: 100%;

    p:first-of-type {
      margin-bottom: 3px;
    }

    .req {
      color: red;
    }

    .MuiTextField-root {
      width: 100%;
    }

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  .button-group {
    width: 100%;
    display: flex;
    gap: 6px;
    justify-content: flex-end;
  }
`;
