import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import { ERROR_CODE } from '@src/errors/code';
import { TESTING_STEP } from '@src/constants';

import { StyledServiceStep } from './index.style';
import { StyledButtonGroup } from '../index.style';
import SearchProduct from './SearchProduct';
import ProductOrder from './ProductOrder';

const ServiceStep = ({
  testing,
  order,
  activeCustomerId,
  onCreateOrderSuccess,
  onBackStep,
}) => {
  const dispatch = useDispatch();

  const [orderedProducts, setOrderedProducts] = useState([]);
  const [discountAll, setDiscountAll] = useState('');
  const [note, setNote] = useState('');

  const handleSelectProduct = (prod) => {
    let isExist = false;
    let tmpProducts = orderedProducts.map((item) => {
      if (item.name === prod.name && item.id === prod.id) {
        isExist = true;
        return {
          ...item,
          quantity: `${Number(item.quantity) + 1}`,
        };
      }
      return item;
    });

    if (!isExist)
      tmpProducts = [
        ...tmpProducts,
        {
          id: prod.id,
          name: prod.name,
          quantity: 1,
          discount: prod.discount || '0',
          type: prod.type,
          priceOut: prod.priceOut,
          remainQuantity: prod.quantity,
        },
      ];

    // TODO: group product by type

    setOrderedProducts(tmpProducts);
  };

  const handleRemoveProduct = (productId) => {
    const tmpProducts = orderedProducts.filter((prod) => prod.id !== productId);

    setOrderedProducts(tmpProducts);
  };

  const handleChangeProduct = (prod, { name, value }) => {
    const tmpProducts = orderedProducts.map((item) => {
      if (item.id === prod.id)
        return {
          ...item,
          [name]: value,
        };

      return item;
    });

    setOrderedProducts(tmpProducts);
  };

  const fetchMedicinesFromTesting = async (prescription = []) => {
    const productIds = prescription
      .filter((item) => !!item.productId)
      .map((item) => item.productId);

    const res = await apis.products.getProductsByIds(productIds);
    if (!res?.status) return;

    const products = res.result.reduce((prev, curr) => {
      const presProduct = prescription.find(
        (prod) => prod.productId === curr.id,
      );
      if (!presProduct) return prev;

      return [
        ...prev,
        {
          id: curr.id,
          name: curr.name,
          quantity: presProduct.quantity,
          discount: curr.discount || '0',
          type: curr.type,
          priceOut: curr.priceOut,
          remainQuantity: curr.quantity,
        },
      ];
    }, []);

    setOrderedProducts(products);
  };

  const handleErrorCreateOrder = (code = 0, message = '') => {
    if (code === ERROR_CODE.RUN_OUT_PRODUCT) {
      const prod = orderedProducts.find((item) => item.id === message);
      if (prod) {
        dispatch(
          actions.noti.push({
            message: `Sản phẩm ${prod.name} đã hết hàng!`,
            severity: 'error',
          }),
        );
        return;
      }
    }
    dispatch(actions.noti.push({ code: code || 0, severity: 'error' }));
  };

  const createOrder = async () => {
    const products = orderedProducts.map((item) => ({
      productId: item.id,
      discount: Number(item.discount),
      quantity: Number(item.quantity),
    }));

    let execFunc = apis.orders.createOrder;
    if (order) execFunc = apis.orders.updateOrder;

    const res = await execFunc({
      testingId: testing.id,
      orderId: order?.id,
      products,
      note,
      discount: Number(discountAll),
    });

    if (!res?.status) {
      handleErrorCreateOrder(res?.code, res?.message);
      return;
    }

    dispatch(
      actions.noti.push({
        message: order
          ? 'Cập nhật đơn thành công'
          : 'Tạo đơn đơn hàng thành công',
        severity: 'success',
      }),
    );

    onCreateOrderSuccess(res.result);
  };

  const handleCreateOrder = async () => {
    createOrder();
  };

  const initStateFromOrder = () => {
    setNote(order?.note || '');
    setDiscountAll(order?.discount || '');
    const prods = order.products.map((item) => {
      const { discount, quantity, priceOut, product } = item;
      return {
        discount,
        quantity,
        priceOut,
        name: product?.name,
        remainQuantity: product?.quantity,
        id: product?.id,
        type: product?.type,
      };
    });
    setOrderedProducts(prods);
  };

  useEffect(() => {
    if (!testing?.prescription) return;
    if (
      activeCustomerId === testing.customer?.id &&
      !testing?.createdOrder &&
      !order
    ) {
      fetchMedicinesFromTesting(testing.prescription);
    }
    if (
      order?.customer?.id === activeCustomerId &&
      activeCustomerId === testing.customer?.id
    ) {
      initStateFromOrder();
    }
    // TODO: handle when have an existing order
    // when having order, don't fetch medicines from testing, reassign state from order
  }, [testing?.id, activeCustomerId, order]);

  useEffect(() => {
    if (!order) return;
    initStateFromOrder();
  }, [order]);

  return (
    <StyledServiceStep>
      <Typography className="title-service">
        Chọn Kính thuốc và Dịch vụ
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12} lg={4} className="left-col">
          <SearchProduct
            onPick={handleSelectProduct}
            note={note}
            setNote={setNote}
          />
        </Grid>
        <Grid item md={12} lg={8} className="right-col">
          <ProductOrder
            products={orderedProducts}
            onRemove={handleRemoveProduct}
            onChange={handleChangeProduct}
            discountAll={discountAll}
            setDiscountAll={setDiscountAll}
          />
          <StyledButtonGroup>
            <Button
              size="small"
              variant="outlined"
              onClick={() => onBackStep(TESTING_STEP.DIAGNOSE)}
            >
              Quay lại
            </Button>
            <Button
              size="small"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={handleCreateOrder}
            >
              Tiếp theo
            </Button>
          </StyledButtonGroup>
        </Grid>
      </Grid>
    </StyledServiceStep>
  );
};

export default ServiceStep;
