const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,

  // customer
  EXISTING_CUSTOMER: 1001,
  NOT_FOUND_CUSTOMER: 1002,
  IS_FAMILY_CUSTOMER_WRONG: 1003,
  CUSTOMER_IN_FAMILY: 1004,
  CUSTOMER_HAD_TESTED: 1005,
  CUSTOMER_IN_QUEUE: 1006,

  // product
  EXISTING_PRODUCT: 2001,
  NOT_FOUND_PRODUCT: 2002,
  PRODUCT_EXISTING_IN_ORDER: 2003,

  // test queue
  EXISTING_TEST_QUEUE: 3001,
  NOT_FOUND_TEST_QUEUE: 3002,

  // Testing
  TESTING_IN_PROGRESS: 4001,
  NOT_FOUND_TESTING: 4002,
  NOT_ALLOWED_UPDATE_TESTING: 4003,
  NOT_ALLOWED_CANCEL_TESTING: 4004,

  // Order
  CREATED_ORDER: 5000, // this error will happen when an testing has had an order
  NOT_FOUND_ORDER: 5001,
  INVALID_PRODUCTS: 5002,
  WRONG_TESTING_FLOW: 5003,
  RUN_OUT_PRODUCT: 5004,
  PAID_ORDER: 5005,
  CANCELED_ORDER: 5006,
  // We don't allow deleting an order when the order is being inprogress.
  // To delete an order, it must be PAID or CANCEL
  NOT_COMPLETED_ORDER: 5007,

  // We don't allow to delete an order when it had been created many days ago
  TOO_LATE_TO_DELETE_ORDER: 5008,

  // Facility
  NOT_FOUND_FACILITY: 6001,

  // Account
  NOT_FOUND_ACCOUNT: 7001,
  EXISTING_ACCOUNT: 7002,
  // Auth
  PASSWORD_NOT_MATCH: 7003,

  // ReTestDate
  RETEST_DATE_IN_PENDING: 8001,
  RETEST_DATE_IS_DONE: 8002,
  RETEST_DATE_IS_CANCELLED: 8003,
  NOT_FOUND_RETESTDATE: 8005,

  // Telegram
  EXISTING_CHATID: 9001,
  INVALID_CHATID: 9002,
};

export { ERROR_CODE };
