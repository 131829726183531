import * as customers from './customers';
import * as testQueues from './testQueue';
import * as testings from './testing';
import * as products from './product';
import * as orders from './order';
import * as auth from './auth';
import * as statistics from './statistic';
import * as retestDates from './retestDate';
import * as account from './account';

export default {
  auth,
  customers,
  testQueues,
  testings,
  products,
  orders,
  statistics,
  retestDates,
  account,
};
