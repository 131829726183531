import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import apis from '@src/apis';
import { COLOR } from '@src/styles/color';
import actions from '@src/redux/actions';
import { useDispatch } from 'react-redux';
import { ConfirmDialog } from '../ConfirmDialog';

import { StyledSwitchNotify } from './index.styled';

const SwitchNotify = ({ isActive, onSwitchNotify, onBackToUpdate }) => {
  const [checked, setChecked] = useState(false);
  const [openDialogSwitch, setOpenDialogSwitch] = useState(false);

  const dispatch = useDispatch();

  const handldOpenDialogSwitch = () => {
    setOpenDialogSwitch(true);
  };

  const handleCloseSwitchNotify = () => {
    setOpenDialogSwitch(false);
  };

  const handleSubmitSwitch = async () => {
    const res = await apis.account.switchNotify();

    setChecked(res.result);
    setOpenDialogSwitch(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }

    onSwitchNotify(res.result);

    dispatch(
      actions.noti.push({
        message: res.result
          ? 'Bật nhận thông báo lịch tái khám thành công'
          : 'Tắt nhận thông báo lịch tái khám thành công',
        severity: 'success',
      }),
    );
  };

  useEffect(() => {
    if (!isActive) return;

    setChecked(isActive);
  }, [isActive]);

  return (
    <StyledSwitchNotify>
      <FormControlLabel
        control={
          <Switch
            sx={{
              '.MuiSwitch-track': {
                backgroundColor: checked ? COLOR.primary : COLOR.lightDivider,
              },
            }}
            checked={checked}
            onChange={handldOpenDialogSwitch}
          />
        }
        label={checked ? 'Tắt thông báo' : 'Bật thông báo'}
      />
      <div
        className="edit-chatID"
        onClick={onBackToUpdate}
        role="button"
        tabIndex="0"
      >
        <u>Sửa id nhận thông báo</u>
      </div>
      <ConfirmDialog
        open={openDialogSwitch}
        title={checked ? 'Tắt thông báo' : 'Bật thông báo'}
        description={
          checked
            ? 'Bạn có tắt nhận thông báo lịch tái khám không?'
            : 'Bạn có muốn nhận thông báo lịch tái khám không?'
        }
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onCancel={handleCloseSwitchNotify}
        onClose={handleCloseSwitchNotify}
        onOk={handleSubmitSwitch}
      />
    </StyledSwitchNotify>
  );
};

export default SwitchNotify;
