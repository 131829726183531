import { styled } from '@mui/material';

export const StyledStatistic = styled('div')`
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledStatsRevenueCards = styled('div')`
  margin: 16px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 18px;
`;
