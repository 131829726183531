import { COLOR } from '@src/styles/color';

const { styled, Box } = require('@mui/material');

export const StyledStatusCard = styled(Box)`
  max-width: 150px;
  border-radius: 5px;
  border: 1px solid ${COLOR.divider};
  padding: 3px 6px;
  color: white;
  text-align: center;
  font-weight: 600;
`;
