import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import Layout from '@src/containers/layout';
import { ROUTES } from '@src/constants/route';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import actions from '@src/redux/actions';
import { getCookie } from '@src/utils';
import { ACCESS_TOKEN_KEY } from '@src/constants';

import appRoutes from './appRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const PrivateApp = () => {
  const privateRoutes = appRoutes.filter((route) => route.isPrivate);

  return (
    <Layout>
      <Switch>
        {privateRoutes.map((privateRoute) => (
          <PrivateRoute
            path={privateRoute.path}
            component={privateRoute.component}
            exact
            key={privateRoute.path}
          />
        ))}
        <Route render={() => <Redirect to={ROUTES.HOME} />} />
      </Switch>
    </Layout>
  );
};

const AppRouter = () => {
  const { accessToken, verifying } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isFirstTime, setIsFirstTime] = useState(true);

  if (!nprogress.isStarted()) nprogress.start();

  useEffect(() => {
    nprogress.done();
  });

  useEffect(() => {
    if (!accessToken) {
      const accessTokenFromCookie = getCookie(ACCESS_TOKEN_KEY);
      if (accessTokenFromCookie) {
        dispatch(actions.auth.verifyToken(accessTokenFromCookie));
      }
    }

    setIsFirstTime(false);
  }, []);

  if (isFirstTime || verifying) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((publicRoute) => (
          <PublicRoute
            exact
            path={publicRoute.path}
            component={publicRoute.component}
            restricted={publicRoute.restricted}
            key={publicRoute.path}
          />
        ))}

        <PrivateRoute component={PrivateApp} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
