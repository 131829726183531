import api from './api';

const pickCustomerForTest = async ({
  customerId,
  reason,
  indicators,
  draft,
}) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/testings',
      data: { customerId, reason, indicators, draft },
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

const getInprogressTesting = async (customerId) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/testings/inprogress',
      params: { customerId },
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

const getTestingById = async (testingId) => {
  try {
    const res = await api({
      method: 'GET',
      url: `/testings/${testingId}`,
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

const updateTesting = async ({
  testingId,
  reason,
  indicators,
  prescription,
  note,
  diagnose,
  draft = false,
}) => {
  try {
    const res = await api({
      method: 'PUT',
      url: `/testings/${testingId}`,
      data: { reason, indicators, prescription, note, diagnose, draft },
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

const updateTestingExamination = async ({
  testingId,
  reason,
  indicators,
  prescription,
  note,
  diagnose,
}) => {
  try {
    const res = await api({
      method: 'PUT',
      url: `/testings/${testingId}/force`,
      data: { reason, indicators, prescription, note, diagnose },
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

const cancelTesting = async (testingId) => {
  try {
    const res = await api({
      method: 'POST',
      url: `/testings/${testingId}/cancel`,
    });

    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

export {
  pickCustomerForTest,
  getInprogressTesting,
  updateTesting,
  getTestingById,
  cancelTesting,
  updateTestingExamination,
};
