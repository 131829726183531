const { styled } = require('@mui/material');

export const StyledConfirmOrder = styled('div')`
  margin-top: 6px;

  .border {
    border: 1px solid #dddddd;
    padding: 3px 6px;
  }

  .invoice-cell {
    padding: 12px 6px;
  }

  .header {
    font-weight: 600;
  }

  .prescription-title {
    font-weight: 600;
    text-decoration: underline;
    margin-top: 3px;
  }

  .text-col {
    display: flex;
    gap: 12px;
    margin: 6px 0;

    .diagnose-item {
      margin: 3px 0;
      span {
        margin-left: 6px;
      }
    }
  }

  .note-result {
    width: 100%;
    text-align: center;
    ont-size: 13px;
    font-weight: lighter;
    font-style: italic;
  }

  .order-note {
    margin-top: 16px;
  }
`;

export const StyledTestResult = styled('div')`
  .glass-info {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const StyledInvoice = styled('div')`
  .total-payment {
    width: 100%;
    text-align: right;
    font-weight: 600;
    margin: 10px 0;

    p {
      margin: 2px;
    }
  }
`;

export const StyledBtnGroup = styled('div')`
  display: flex;
  justify-content: end;
  gap: 4px;
`;
