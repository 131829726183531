import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledCustomDatePickerRange = styled('div')`
  display: flex;

  .date-range {
    display: flex;
    background-color: ${COLOR.white};
    border-radius: ${BORDER_RADIUS};
  }

  .input-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin: 0 16px;
  }

  .icon {
    cursor: pointer;
  }

  .light-color {
    color: ${COLOR.light};
  }

  .MuiOutlinedInput-root {
    width: 152px;

    input {
      padding: 8.5px 6px;
      height: fit-content;
      color: ${COLOR.dark};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const CustomDateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: theme.palette.transparentColor.main,
      color: theme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
        color: COLOR.white,
      },
    }),
    ...(isStartOfHighlighting && {
      backgroundColor: theme.palette.primary.main,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      '.Mui-selected': {
        color: `${COLOR.white} !important`,
      },
    }),
    ...(isEndOfHighlighting && {
      backgroundColor: theme.palette.primary.main,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      '.Mui-selected': {
        color: `${COLOR.white} !important`,
      },
    }),
  }),
);
