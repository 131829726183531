import React, { useEffect, useState } from 'react';
import {
  PageFrame,
  Card,
  CustomTable,
  StatusCard,
  orderTypeCards,
} from '@src/components';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GENDER, TESTING_STATUS, TESTING_STEP } from '@src/constants';
import { calculateAge, convertFormatDate, delimitNumber } from '@src/utils';
import { Visibility } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import apis from '@src/apis';
import { StyledCustomer } from './index.style';

const Customer = () => {
  const [customer, setCustomer] = useState({});
  const [testings, setTestings] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });

  const { customerId } = useParams();

  const fetchCustomer = async () => {
    const res = await apis.customers.getCustomerById(customerId);
    if (!res?.status) return;

    setCustomer(res.result);
  };

  const fetchTestingsByCustomerId = async () => {
    const res = await apis.orders.getOrderByCustomerId(customerId, {
      page: paging.page,
    });
    if (!res?.status) return;

    setTestings(res.result.orders);
    setPaging({ ...paging, total: res.result.total });
  };

  const renderDiagnoseText = (testing) => {
    const { leftEye, rightEye, general } = testing.testing.diagnose;

    if (testing.testing.status === TESTING_STATUS.DONE) {
      return `${leftEye ? ` Mắt trái: ${leftEye} - ` : ''} 
      ${rightEye ? ` Mắt phải: ${rightEye} - ` : ''} 
      ${general ? ` Chung: ${general}` : ''}`;
    }

    return 'Không có chuẩn đoán';
  };

  const renderStatusTestingText = (testingStep) => {
    const textTestingStep = {
      NEW: 'Khách hàng mới',
      WAITING: 'Đang chờ',
      TEST: 'Đang kiểm tra',
      DIAGNOSE: 'Đang chuẩn đoán',
      GLASS_MEDICINE_SERVICE: 'Đang mua thuốc',
      CONFIRM_ORDER: 'Đang xác nhận hóa đơn',
      CANCELED: 'Hủy',
      DONE: 'Đã xong',
      CANCEL_FROM_QUEUE: 'Hủy từ hàng đợi',
    };
    const keys = Object.keys(TESTING_STEP);
    const foundKey = keys.find((key) => TESTING_STEP[key] === testingStep);
    return textTestingStep[foundKey];
  };

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const columns = [
    {
      field: 'status',
      align: 'left',
      id: 'status',
      title: 'Trạng thái',
      render: (testing) => (
        <Box display="flex">
          <StatusCard {...orderTypeCards[testing.status]} />
        </Box>
      ),
    },
    {
      field: 'reason',
      align: 'left',
      id: 'reason',
      title: 'Lý do khám',
      render: (testing) => testing?.testing.reason,
    },
    {
      field: 'createdAt',
      align: 'left',
      id: 'createdAt',
      title: 'Ngày khám',
      render: (testing) =>
        convertFormatDate(testing.createdAt, 'HH:mm DD-MM-YYYY'),
    },
    {
      field: 'diagnose',
      align: 'center',
      id: 'diagnose',
      title: 'Chuẩn đoán',
      render: (testing) => (
        <div className="diagnose">{renderDiagnoseText(testing)}</div>
      ),
    },
    {
      id: 'actions',
      title: 'Hành động',
      align: 'center',
      render: (testing) => (
        <>
          <Link to={`/hoa-don/${testing.id}`}>
            <Tooltip title="Thông tin chi tiết">
              <IconButton>
                <Visibility />
              </IconButton>
            </Tooltip>
          </Link>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchCustomer();
  }, []);

  useEffect(() => {
    fetchTestingsByCustomerId();
  }, [paging.page]);

  return (
    <PageFrame title="Thông tin khách hàng ">
      <StyledCustomer>
        <div className="container">
          <div className="item">
            <b>Tên</b>: {customer.name}
          </div>
          <div className="item">
            <b>Tuổi</b>: {calculateAge(customer.birthday)}
          </div>
          <div className="item">
            <b>Giới tính</b> : {GENDER[customer.gender]}
          </div>
          <div className="item">
            <b>Số điện thoại</b>: {customer.phoneNumber}
          </div>
          <div className="item">
            <b>Địa chỉ</b> : {customer.address}
          </div>
          <div className="item">
            <b>Ngày khám gần nhất: </b>
            {(testings.length &&
              convertFormatDate(testings[0].createdAt, 'HH:mm DD-MM-YYYY')) ||
              'Không có'}
          </div>
          <div className="item">
            <b>Tiền sử bệnh</b> : {customer.anamnesis || 'Không có'}
          </div>
          <div className="item">
            <b>Trạng thái khám: </b>
            {renderStatusTestingText(customer.testingStep)}
          </div>
          <div className="item">
            <b>Doanh thu</b>: {delimitNumber(customer.revenue)}
          </div>
        </div>

        <Card title="Đơn khám">
          <CustomTable
            columns={columns}
            data={testings}
            total={paging.total}
            page={paging.page}
            onChangePage={handleChangePage}
          />
        </Card>
      </StyledCustomer>
    </PageFrame>
  );
};

export default Customer;
