import React, { useState } from 'react';
import * as yup from 'yup';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import actions from '@src/redux/actions';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import { Card } from '../Card';
import { InputControl } from '../InputControl';

import { StyledChangePassword } from './index.style';
import { ProcessHandler } from '../common';

const initialPassword = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchema = yup.object({
  oldPassword: yup
    .string('Nhập mật khẩu cũ')
    .required('Vui lòng nhập mật khẩu cũ'),
  newPassword: yup
    .string('Nhập mật khẩu mới')
    .required('Vui lòng nhập mật khẩu mới'),
  confirmNewPassword: yup
    .string('Nhập lại mật khẩu mới')
    .oneOf([yup.ref('newPassword'), null], 'Mật khẩu không trùng khớp')
    .required('Vui lòng nhập lại mật khẩu mới'),
});

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmitForm = async (values, helpers) => {
    setLoading(true);
    const res = await apis.auth.changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.confirmNewPassword,
    });
    setLoading(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Thay đổi mật khẩu thành công',
        severity: 'success',
      }),
    );
    helpers.resetForm();
  };

  const formik = useFormik({
    initialValues: initialPassword,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  return (
    <Card title="Đổi mật khẩu">
      <form onSubmit={formik.handleSubmit}>
        <StyledChangePassword>
          <div className="item-input">
            <InputControl
              label="Mật khẩu cũ"
              size="small"
              placeholder="Nhập mật khẩu cũ"
              name="oldPassword"
              className="input"
              type="password"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.oldPassword)}
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
            />
          </div>
          <div className="item-input">
            <InputControl
              label="Mật khẩu mới"
              size="small"
              placeholder="Nhập mật khẩu mới"
              name="newPassword"
              className="input"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.newPassword)}
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
          </div>
          <div className="item-input">
            <InputControl
              label="Xác nhận mật khẩu mới"
              size="small"
              placeholder="Nhập lại mật khẩu mới"
              name="confirmNewPassword"
              className="input"
              type="password"
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.confirmNewPassword)}
              helperText={
                formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword
              }
            />
          </div>
          <div className="item-input">
            <Button type="submit" variant="contained">
              <ProcessHandler loading={loading} size={30} color="secondary">
                Xác nhận
              </ProcessHandler>
            </Button>
          </div>
        </StyledChangePassword>
      </form>
    </Card>
  );
};

export default ChangePassword;
