import React, { useState } from 'react';
import { StyledMainPanel, StyledWrapper } from './index.style';
import Sidebar from './Sidebar';
import Content from './Content';
import NavBar from './NavBar';

const LayoutApp = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(true);

  const handleOpenSidebar = () => setOpenSidebar(true);
  const handleCloseSidebar = () => setOpenSidebar(false);

  return (
    <StyledWrapper>
      <Sidebar
        openSidebar={openSidebar}
        handleOpenSidebar={handleOpenSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
      <StyledMainPanel className={`${openSidebar && 'open-sidebar'}`}>
        <NavBar
          openSidebar={openSidebar}
          handleOpenSidebar={handleOpenSidebar}
        />
        <Content openSidebar={openSidebar}>{children}</Content>
      </StyledMainPanel>
    </StyledWrapper>
  );
};
export default LayoutApp;
