const { styled } = require('@mui/material');

export const StyledTesting = styled('div')`
  .current-testing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;
  }
`;

export const StyledButtonGroup = styled('div')`
  display: flex;
  justify-content: end;
  gap: 6px;
  margin-top: 60px;
`;
