import React from 'react';
import SelectOption from '@src/components/SelectOption/SelectOption';
import { PRODUCT_TYPE } from '@src/constants';

const productType = {
  key: 'select-options',
  title: 'Lọc sản phẩm',
  type: 'select-options',
  name: 'type product',
  options: [
    { value: PRODUCT_TYPE.ALL, label: 'Tất cả' },
    { value: PRODUCT_TYPE.MEDICINE, label: 'Thuốc' },
    { value: PRODUCT_TYPE.SERVICE, label: 'Dịch vụ' },
    { value: PRODUCT_TYPE.GLASSES, label: 'Kính mắt' },
    { value: PRODUCT_TYPE.OTHER, label: 'Khác' },
  ],
};

const ListFilter = ({ onChangeSelect }) => {
  const handleChangeProductType = (name, value) => {
    onChangeSelect(name, value);
  };

  return (
    <div className="list-filter">
      <div className="item-filter">
        <SelectOption
          key={productType.key}
          className="select-option"
          title={productType.title}
          name={productType.name}
          options={productType.options}
          onChange={handleChangeProductType}
        />
      </div>
    </div>
  );
};

export default ListFilter;
