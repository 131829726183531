import { actionTypes } from './actions';

export const initialState = {
  accessToken: null,
  verifying: false,
  account: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_TOKEN:
      return { ...state, verifying: true };

    case actionTypes.VERIFY_TOKEN_SUCCESS: {
      const { accessToken, account } = action;
      return {
        ...state,
        verifying: false,
        accessToken,
        account,
      };
    }

    case actionTypes.VERIFY_TOKEN_FAILURE:
      return { ...state, verifying: false };

    case actionTypes.UPDATE_ACCOUNT_INFO: {
      const { account } = action;
      return { ...state, account: { ...state.account, ...account } };
    }

    case actionTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
