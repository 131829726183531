import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const NoGlass = ({ indicators }) => (
  <div className="glass-info">
    <Typography className="prescription-title">KHÔNG KÍNH</Typography>
    <Table sx={{ borderCollapse: 'collapse' }} aria-label="Khong Kinh">
      <TableHead>
        <TableRow>
          <TableCell className="border header" align="center">
            Mắt
          </TableCell>
          <TableCell className="border header" align="center">
            Thị lực
          </TableCell>
          <TableCell className="border header" align="center">
            Kính lỗ
          </TableCell>
          <TableCell className="border header" align="center">
            PD(mm)
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '13%' }}
            align="center"
          >
            CT
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '13%' }}
            align="center"
          >
            EOM
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '13%' }}
            align="center"
          >
            NPC
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            className="border"
            component="th"
            scope="row"
            align="center"
          >
            Phải (OD)
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.noMpVa}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.noMpPh}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.noPD}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.noCT}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.noEOM}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.noNPC}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className="border"
            component="th"
            scope="row"
            align="center"
          >
            Trái (OS)
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.noMtVa}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.noMtPh}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

export default NoGlass;
