import React, { useEffect, useState } from 'react';
import qrGetChatIdImg from '@src/assets/images/qr-get-my-chat-id.jpg';
import qrEyesClinicBotImg from '@src/assets/images/qr-eye-clinic-bot.jpg';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { Card } from '../Card';
import { InputControl } from '../InputControl';
import { ProcessHandler } from '../common';
import SwitchNotify from './SwitchNotify';

import { StyledSettingChat } from './index.styled';

const initialChatID = {
  chatId: '',
  active: false,
};

const validationSchema = yup.object({
  chatId: yup
    .string('Nhập chatID sau khi lấy được từ &apos;@GETMYCHATID_BOT&apos;')
    .required('Vui lòng không để trống chatID của bạn'),
});

const SettingChat = () => {
  const { account } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isOpenInput, setIsOpenInput] = useState();

  const handleOpenInput = () => {
    setIsOpenInput(false);
  };

  const handleSwitchNotify = (stateSwitch) => {
    account.telegramNotification.active = stateSwitch;
  };

  const handleOpenSwitchNotify = () => {
    setIsOpenInput(true);
  };

  const handleSubmitForm = async (values, helpers) => {
    if (values.chatId === account.telegramNotification?.chatId) return;

    setLoading(true);
    const res = await apis.account.createChatId(values.chatId);
    setLoading(false);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }

    account.telegramNotification = res.result;
    setIsOpenInput(true);

    dispatch(
      actions.noti.push({
        message: account?.chatId
          ? 'Cập nhật chatID thành công'
          : 'Thêm chat ID thành công',
        severity: 'success',
      }),
    );
    helpers.resetForm();
  };

  const formik = useFormik({
    initialValues: initialChatID,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  useEffect(() => {
    if (!account || !account.telegramNotification) return;
    const { chatId = '', active = false } = account.telegramNotification || {};
    formik.setValues({ chatId, active });
    setIsOpenInput(Boolean(chatId));
  }, [account]);

  const renderInputChatId = () => {
    const inputProps = {
      label: 'Chat ID',
      size: 'small',
      placeholder: 'Nhập chat ID',
      name: 'chatId',
      className: 'input',
      type: 'text',
      value: formik.values.chatId,
      onChange: formik.handleChange,
      error: Boolean(formik.errors.chatId),
      helperText: formik.touched.chatId && formik.errors.chatId,
    };

    return (
      <>
        {account?.telegramNotification ? (
          <div>
            <InputControl {...inputProps} />
            <div
              className="back-switch-text"
              onClick={handleOpenSwitchNotify}
              role="button"
              tabIndex="0"
            >
              <u>Quay lại</u>
            </div>
          </div>
        ) : (
          <InputControl {...inputProps} />
        )}
      </>
    );
  };

  return (
    <Card title="Cài đặt nhận thông báo">
      <form onSubmit={formik.handleSubmit}>
        <StyledSettingChat>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {isOpenInput ? (
                <SwitchNotify
                  onSwitchNotify={handleSwitchNotify}
                  isActive={account.telegramNotification?.active}
                  onBackToUpdate={handleOpenInput}
                />
              ) : (
                renderInputChatId()
              )}
              <div className="tutorials">
                <i>
                  <div>B1: Tải ứng dụng telegram.</div>
                  <div>
                    B2: Quét mã QR &apos;@GETMYCHATID_BOT&apos; vào telegram sau
                    đó bấm &apos;start&apos; để lấy &apos;User ID&apos;.
                  </div>
                  <div>
                    B3: Nhập &apos;User ID&apos; và bấm &apos;THÊM CHAT
                    ID&apos;.
                  </div>
                  <div>
                    B4: Tiếp tục quét mã QR truy cập vào bot
                    &apos;@EYESCLINICBOT&apos;.
                  </div>
                  <div>
                    B5: Bấm &apos;start&apos; để bắt đầu nhận thông báo lịch tái
                    khám.
                  </div>
                </i>
              </div>
              <div className="item-button">
                {!isOpenInput && (
                  <Button type="submit" variant="contained">
                    <ProcessHandler
                      loading={loading}
                      size={30}
                      color="secondary"
                    >
                      {account?.telegramNotification?.chatId
                        ? 'Sửa chat ID'
                        : 'Thêm chat ID'}
                    </ProcessHandler>
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="image-container">
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <img
                      className="image-qr-code"
                      src={qrGetChatIdImg}
                      alt="no data"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      className="image-qr-code"
                      src={qrEyesClinicBotImg}
                      alt="no data"
                    />
                  </Grid>
                </Grid>
                <i className="text">Quét QR để lấy thông tin</i>
              </div>
            </Grid>
          </Grid>
        </StyledSettingChat>
      </form>
    </Card>
  );
};

export default SettingChat;
