const { styled } = require('@mui/material');

export const StyledPageFrame = styled('div')`
  .title {
    font-weight: 600;
    font-size: 23px;
  }

  .content {
    padding: 12px 0;
  }
`;
