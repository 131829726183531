import React, { useState } from 'react';
import moment from 'moment';
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import { KeyboardArrowDown, Loop } from '@mui/icons-material';

import { StyledCustomDatePickerRange } from './index.style';

const CustomDatePickerRange = ({
  value = [null, null],
  optionsDatePicker,
  isRefresh,
  onChange,
  onSelectDateBackOption,
  handleRefresh,
}) => {
  const [anchorSelectDay, setAnchorSelectDay] = useState(null);

  const handleClickSelectDay = (e) => setAnchorSelectDay(e.currentTarget);
  const handleCloseSelectDay = () => setAnchorSelectDay(null);

  const handleChange = (label) => (newValue) => {
    if (!newValue || !newValue?.getDate() || !onChange) return;
    if (label === 'startDate') {
      if (newValue !== value[0]) onChange([newValue, value[1]]);
    } else if (newValue !== value[1]) onChange([value[0], newValue]);
  };

  const handleSelectDay = (day) => {
    let startDate;
    let endDate;

    if (onSelectDateBackOption) {
      [startDate, endDate] = onSelectDateBackOption(day);
    } else {
      endDate = moment().endOf('day').toString();

      if (day < 0) {
        startDate = moment()
          .subtract(Math.abs(day), 'd')
          .startOf('day')
          .toString();
      } else {
        startDate = moment().startOf('day').toString();
        endDate = moment().add(day, 'd').endOf('day').toString();
      }
    }

    onChange([startDate, endDate]);
    handleCloseSelectDay();
  };

  return (
    <StyledCustomDatePickerRange>
      <div className="date-range">
        <div className="input-wrapper">
          <DatePicker
            name="startDate"
            inputFormat="dd/MM/yyyy"
            mask="__-__-____"
            value={value[0]}
            onChange={handleChange('startDate')}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <Typography className="light-color">Đến</Typography>
          <DatePicker
            name="endDate"
            inputFormat="dd/MM/yyyy"
            mask="__-__-____"
            value={value[1]}
            onChange={handleChange('endDate')}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <KeyboardArrowDown
            className="icon light-color"
            onClick={handleClickSelectDay}
          />
          <Menu
            anchorEl={anchorSelectDay}
            open={Boolean(anchorSelectDay)}
            onClose={handleCloseSelectDay}
          >
            {optionsDatePicker.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => handleSelectDay(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {isRefresh && (
        <IconButton
          aria-label="refresh"
          className="light-color"
          onClick={handleRefresh}
        >
          <Loop />
        </IconButton>
      )}
    </StyledCustomDatePickerRange>
  );
};
export default CustomDatePickerRange;
