/**
 *
 * @param {*} products [{ priceOut, quantity, discount }]
 * @param {*} discountAll
 * @returns
 */
export const calculateTotalInvoice = (products = [], discountAll = '0') => {
  if (!products.length) return { total: 0, reduction: 0, mustPay: 0 };

  const result = products.reduce(
    (prev, curr) => ({
      total: prev.total + curr.priceOut * curr.quantity,
      reduction:
        prev.reduction +
        (curr.priceOut *
          (Math.abs(Number(curr.discount)) + Math.abs(Number(discountAll))) *
          curr.quantity) /
          100,
    }),
    {
      total: 0,
      reduction: 0,
    },
  );

  return {
    ...result,
    mustPay: result.total - result.reduction,
  };
};
