const { styled, Dialog } = require('@mui/material');
const { COLOR } = require('@src/styles/color');

export const StyledBaseDialog = styled(Dialog)`
  min-width: ${(props) => props.width || '550px'};
  max-width: ${(props) => props.maxWidth};

  .dialog-title {
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .sub-title {
    color: ${COLOR.dark};
    margin: 5px 0;
  }
`;
