import React from 'react';
import { Box } from '@mui/material';
import { calculateAge, formatPhoneNumber } from '@src/utils';
import { useSelector } from 'react-redux';
import { GENDER } from '@src/constants';
import { StyledHeader } from './index.style';

const Header = ({ title, customer = {} }) => {
  const { account } = useSelector((state) => state.auth);

  return (
    <StyledHeader>
      <div className="header-print">
        <div className="logo-invoice">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <div className="address">
          <h4>{account?.facility?.name?.toUpperCase()}</h4>
          <p>Địa chỉ: {account?.facility?.address}</p>
          <p>Hotline: {formatPhoneNumber(account?.facility?.phoneNumber)}</p>
          {account?.facility?.email && <p>Email: {account?.facility?.email}</p>}
        </div>
      </div>
      <hr className="divider" />
      <h5 className="title-print">{title}</h5>
      <div className="customer-info">
        <div>
          Họ tên: <strong>{customer.name}</strong>
        </div>
        <Box fullWidth display="flex" justifyContent="end">
          <div className="float-right">
            Giới tính: <strong>{GENDER[customer.gender]}</strong>
          </div>
        </Box>
        <div>
          Tuổi: <strong>{calculateAge(customer.birthday)}</strong>
        </div>
        <Box fullWidth display="flex" justifyContent="end">
          <div className="float-right">
            SĐT: <strong>{customer.phoneNumber}</strong>
          </div>
        </Box>
        <div>
          Địa chỉ: <strong>{customer.address}</strong>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
