import Examination from './Examination';
import Product from './Product';

export const UPDATE_TESTING_TAB_KEYS = {
  EXAMINATION: 'EXAMINATION',
  PRODUCT: 'PRODUCT',
};

export default [
  {
    value: UPDATE_TESTING_TAB_KEYS.EXAMINATION,
    label: 'Chuẩn đoán',
    component: Examination,
  },
  {
    value: UPDATE_TESTING_TAB_KEYS.PRODUCT,
    label: 'Đơn thuốc',
    component: Product,
  },
];
