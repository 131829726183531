import React from 'react';
import { CustomTable } from '@src/components';
import { IconButton, Input, InputBase } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { StyledPrescription } from './index.style';

const Prescription = ({ medicines, onChange, onRemove }) => {
  const handleChange = (med, target) => {
    onChange(med, target);
  };

  const handleRemoveMedicine = (item) => () => {
    onRemove(item);
  };

  const columns = [
    {
      field: 'name',
      align: 'left',
      id: 'name',
      title: 'Tên thuốc',
    },
    {
      field: 'quantity',
      align: 'left',
      id: 'quantity',
      title: 'Số lượng',
      render: (item) => (
        <InputBase
          placeholder="0"
          type="number"
          name="quantity"
          value={item.quantity}
          onChange={(event) => handleChange(item, event.target)}
          className="quantity-input"
        />
      ),
    },
    {
      field: 'instruction',
      align: 'left',
      id: 'instruction',
      title: 'Chỉ dẫn',
      render: (item) => (
        <Input
          placeholder="Nhập chỉ dẫn..."
          name="instruction"
          value={item.instruction}
          onChange={(event) => handleChange(item, event.target)}
          className="ins-input"
        />
      ),
    },
    {
      field: 'action',
      align: 'right',
      id: 'action',
      title: 'Hành động',
      render: (item) => (
        <IconButton onClick={handleRemoveMedicine(item)}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <StyledPrescription>
      <CustomTable showNumber page={1} columns={columns} data={medicines} />
    </StyledPrescription>
  );
};

export default Prescription;
