const { styled } = require('@mui/material');

export const StyledReTestDate = styled('div')`
  .option-advance {
    font-size: 12px;
    color: #1da1f2;
    cursor: pointer;
    width: fit-content;
    margin-top: 12px;
  }

  .reTestDate-option {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .input-date {
      width: 160px;
    }

    .note {
      width: 60%;
    }
  }
`;
