import React from 'react';
import { COLOR } from '@src/styles/color';

import { StyledStatusCard } from './index.style';

const orderTypeCards = {
  PENDING: {
    name: 'Đang xử lý',
    color: COLOR.blue,
  },
  CANCELLED: {
    name: 'Đã hủy',
    color: COLOR.danger,
  },
  PAID: {
    name: 'Đã thanh toán',
    color: COLOR.lightGreen,
  },
};

const productTypeCards = {
  SERVICE: {
    name: 'Dịch vụ',
    color: COLOR.blue,
  },
  MEDICINE: {
    name: 'Thuốc',
    color: COLOR.lightGreen,
  },
  GLASSES: {
    name: 'Kính',
    color: COLOR.danger,
  },
  OTHER: {
    name: 'Khác',
    color: COLOR.purple,
  },
};

const testingStatusCard = {
  INPROGRESS: {
    name: 'Đang khám',
    color: COLOR.orange,
  },
  DONE: {
    name: 'Hoàn thành',
    color: COLOR.lightGreen,
  },
  CANCELED: {
    name: 'Đã hủy',
    color: COLOR.danger,
  },
};

const retestDateStatusCard = {
  PENDING: {
    name: 'Đang xử lý',
    color: COLOR.orange,
  },
  DONE: {
    name: 'Hoàn thành',
    color: COLOR.lightGreen,
  },
  CANCELLED: {
    name: 'Đã hủy',
    color: COLOR.danger,
  },
};

const StatusCard = ({ name, color }) => (
  <StyledStatusCard sx={{ background: color }}>{name}</StyledStatusCard>
);

export {
  StatusCard,
  productTypeCards,
  orderTypeCards,
  testingStatusCard,
  retestDateStatusCard,
};
