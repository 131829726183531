import { COLOR_SETS } from '@src/styles/color';
import { actionTypes } from './actions';

export const initialState = {
  colorSet: COLOR_SETS[0],
};

export default function changeThemeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_THEME: {
      const { colorSet } = action;

      return {
        ...state,
        colorSet,
      };
    }

    default:
      return state;
  }
}
