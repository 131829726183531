import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { InputControl, ProcessHandler } from '@src/components';
import {
  initialIndicators,
  parseIndicators,
} from '@src/containers/Testing/indicators';
import { StyledTestStep } from '@src/containers/Testing/TestStep/index.style';
import Indicators from '@src/containers/Testing/TestStep/Indicators';
import apis from '@src/apis';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import {
  StyledUpdateTestingButtonGroup,
  StyledUpdateTestingDialog,
} from '../index.style';

const initDiagnose = {
  general: '',
  leftEye: '',
  rightEye: '',
};

const Examination = ({ testing, onClose, onUpdateOrderSuccess }) => {
  const [indicators, setIndicators] = useState(initialIndicators);
  const [reason, setReason] = useState('');
  const [diagnose, setDiagnose] = useState(initDiagnose);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChangeIndicators = (name, value) => {
    setIndicators({ ...indicators, [name]: value });
  };

  const handleChangeDiagnose = (event) => {
    setDiagnose({ ...diagnose, [event.target.name]: event.target.value });
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const handleSaveExamination = async () => {
    setLoading(true);
    const res = await apis.testings.updateTestingExamination({
      testingId: testing.id,
      reason,
      diagnose,
      indicators: JSON.stringify(indicators),
    });
    setLoading(false);
    if (!res?.status) return;

    dispatch(
      actions.noti.push({
        message: 'Cập nhật đơn khám thành công',
        severity: 'success',
      }),
    );
    onUpdateOrderSuccess();
  };

  useEffect(() => {
    if (!testing) return;

    setDiagnose(testing?.diagnose);
    setReason(testing?.reason);
    setIndicators(parseIndicators(testing.indicators));
  }, [testing]);

  return (
    <StyledTestStep>
      <Grid item xs={12} md={12} className="left-col">
        <StyledUpdateTestingDialog>
          <Typography className="col-title">Chuẩn đoán</Typography>
          <InputControl
            placeholder="Chẩn đoán chung"
            size="small"
            name="general"
            key="general"
            className="general-input"
            fullWidth
            value={diagnose.general}
            onChange={handleChangeDiagnose}
          />
          <div className="diagnose">
            <Grid item xs={12} md={6} className="left-col">
              <InputControl
                placeholder="Mắt phải"
                name="rightEye"
                key="rightEye"
                size="small"
                value={diagnose.rightEye}
                onChange={handleChangeDiagnose}
              />
            </Grid>
            <Grid item xs={12} md={6} className="left-col">
              <InputControl
                placeholder="Mắt trái"
                name="leftEye"
                key="leftEye"
                size="small"
                value={diagnose.leftEye}
                onChange={handleChangeDiagnose}
              />
            </Grid>
          </div>
        </StyledUpdateTestingDialog>
      </Grid>
      <Box padding="0 12px 16px 12px">
        <div className="reason-testing">
          <Typography>Lý do khám</Typography>
          <TextField
            className="input"
            name="reason"
            value={reason}
            onChange={handleChangeReason}
            placeholder="Lý do khám bệnh"
          />
        </div>
        <Indicators indicators={indicators} onChange={handleChangeIndicators} />
      </Box>
      <StyledUpdateTestingButtonGroup>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Huỷ
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleSaveExamination}
        >
          <ProcessHandler loading={loading} size={25}>
            Lưu
          </ProcessHandler>
        </Button>
      </StyledUpdateTestingButtonGroup>
    </StyledTestStep>
  );
};

export default Examination;
