import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import actions from '@src/redux/actions';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { BaseDialog } from '@src/components';
import PrintOrder from '.';
import InvoicePrint from './InvoicePrint';
import TestResultPrint from './TestResultPrint';
import { StyledPrintDialog } from './index.style';

const initialPrintOption = {
  testResult: true,
  invoice: true,
};

const PrintDialog = ({ open, testing, order, reTestDate, onClose }) => {
  const dispatch = useDispatch();
  const printOrderRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printOrderRef.current,
  });

  const [printOption, setPrintOption] = useState(initialPrintOption);

  const handleChangeCheckBox = (event) => {
    const { name, checked } = event.target;
    setPrintOption({ ...printOption, [name]: checked });
  };

  const handlePrintNow = () => {
    if (!printOption.invoice && !printOption.testResult) {
      dispatch(
        actions.noti.push({
          message: 'Phải chọn ít nhất 1 đơn để in',
          severity: 'info',
        }),
      );
      return;
    }
    handlePrint();
  };

  return (
    <>
      <Box sx={{ width: '60%', display: 'none' }}>
        <Box ref={printOrderRef}>
          {printOption.testResult && (
            <PrintOrder
              title="KẾT QUẢ KHÁM"
              customer={order?.customer}
              date={testing?.createdAt}
            >
              <TestResultPrint
                testing={testing}
                diagnose={testing?.diagnose}
                reTestDate={reTestDate}
                prescription={testing?.prescription}
              />
            </PrintOrder>
          )}
          {printOption.invoice && (
            <PrintOrder
              title="HÓA ĐƠN"
              customer={order?.customer}
              date={order?.createdAt}
            >
              <InvoicePrint
                products={order?.products}
                discountAll={order?.discount}
              />
            </PrintOrder>
          )}
        </Box>
      </Box>
      <BaseDialog
        open={open}
        onClose={onClose}
        title="In kết quả và hóa đơn khám"
      >
        <StyledPrintDialog>
          <FormControlLabel
            control={
              <Checkbox
                checked={printOption.testResult}
                name="testResult"
                onChange={handleChangeCheckBox}
              />
            }
            label="Kết quả khám"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={printOption.invoice}
                name="invoice"
                onChange={handleChangeCheckBox}
              />
            }
            label="Hóa đơn"
          />

          <div className="btn-group">
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={onClose}
            >
              Hủy
            </Button>
            <Button size="small" onClick={handlePrintNow} variant="contained">
              In ngay
            </Button>
          </div>
        </StyledPrintDialog>
      </BaseDialog>
    </>
  );
};

export default PrintDialog;
