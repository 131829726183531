import { PAGINATION_LIMIT } from '@src/constants';
import api from './api';

const getReTestDates = async ({
  limit = PAGINATION_LIMIT,
  page = 1,
  search = '',
  sort = 'createdAt_desc',
  fields,
  dateField,
  searchFields,
  status,
  type,
  startDate,
  endDate,
}) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/re-test-dates',
      params: {
        limit,
        offset: (page - 1) * PAGINATION_LIMIT,
        search,
        fields,
        sort,
        searchFields,
        status,
        type,
        dateField,
        startDate,
        endDate,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const createRetestDate = async ({
  date,
  type,
  optionValue,
  customerId,
  testingId,
}) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/re-test-dates',
      data: { date, type, optionValue, customerId, testingId },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const removeReTestDate = async (reTestDateId) => {
  try {
    const res = await api({
      method: 'DELETE',
      url: `/re-test-dates/${reTestDateId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};
const getReTestDateByTestingId = async (testingId) => {
  try {
    const res = await api({
      method: 'get',
      url: `/re-test-dates/testing/${testingId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const updateReTestDate = async ({
  reTestDateId,
  date,
  status,
  type,
  optionValue,
}) => {
  try {
    const res = await api({
      method: 'PUT',
      url: `/re-test-dates/${reTestDateId}`,
      data: { date, status, type, optionValue },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export {
  getReTestDates,
  createRetestDate,
  getReTestDateByTestingId,
  updateReTestDate,
  removeReTestDate,
};
