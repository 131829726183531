import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  orderTypeCards,
  PageFrame,
  ProcessHandler,
  StatusCard,
} from '@src/components';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Print, Edit } from '@mui/icons-material';
import apis from '@src/apis';
import { ROUTES } from '@src/constants/route';
import { ORDER_STATUS } from '@src/constants';
import actions from '@src/redux/actions';
import { calculateAge } from '@src/utils';
import Invoice from '../Testing/ConfirmOrderStep/Invoice';
import PrintDialog from '../Testing/ConfirmOrderStep/PrintOrder/PrintDialog';
import TestResult from '../Testing/ConfirmOrderStep/TestResult';

import { StyledConfirmOrder } from '../Testing/ConfirmOrderStep/index.style';
import { StyledButtonGroup, StyledOrderDetail } from './index.style';
import UpdateOrderDialog from './UpdateOrderDialog';
import UpdateTestingDialog from './UpdateTesting';

const OrderDetail = () => {
  const { orderId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [openPrint, setOpenPrint] = useState(false);
  const [reTestDate, setReTestDate] = useState();
  const [openEditing, setOpenEditing] = useState(false);
  const [openExamination, setOpenExamination] = useState(false);

  const handleClosePrint = () => setOpenPrint(false);

  const handleOpenPrint = () => {
    if (order?.status === ORDER_STATUS.PAID) {
      setOpenPrint(true);
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'Hóa đơn này chưa được thanh toán',
      }),
    );
  };

  const fetchReTestDateByTestingId = async (testing) => {
    if (!testing) return;
    const res = await apis.retestDates.getReTestDateByTestingId(testing.id);

    if (!res?.status) return;

    setReTestDate(res.result);
  };

  const fetchOrder = async () => {
    setLoading(true);
    const res = await apis.orders.getOrderById(orderId);

    if (!res?.status) {
      if (history.length > 0) history.goBack();
      else history.push(ROUTES.ORDER);
      return;
    }
    await fetchReTestDateByTestingId(res.result?.testing);
    setLoading(false);

    setOrder(res.result);
  };

  const isShowUpdateExamination = order?.status !== ORDER_STATUS.PENDING;

  const handleUpdatedOrderSuccess = () => {
    fetchOrder();
    setOpenEditing(false);
    setOpenExamination(false);
  };

  useEffect(() => {
    if (!orderId) return;
    fetchOrder();
  }, [orderId]);

  return (
    <PageFrame title="Chi tiết hóa đơn">
      <StyledOrderDetail>
        <StatusCard {...orderTypeCards[order?.status]} />
        <StyledButtonGroup>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleOpenPrint}
          >
            <ProcessHandler loading={loading}>
              <Print />
              <Typography variant="span" ml="3px">
                In hóa đơn
              </Typography>
            </ProcessHandler>
          </Button>
          {isShowUpdateExamination && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenExamination(true)}
            >
              <Edit />
              <Typography variant="span" ml="3px">
                Chỉnh sửa đơn khám
              </Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenEditing(true)}
          >
            <Edit />
            <Typography variant="span" ml="3px">
              Chỉnh sửa hóa đơn
            </Typography>
          </Button>
        </StyledButtonGroup>

        <Divider />
        <div className="info-customer">
          Thông tin khách hàng:{' '}
          <b>
            {order?.customer?.name} -{' '}
            {`${calculateAge(order?.customer?.birthday)} tuổi`} -{' '}
            {order?.customer?.address} - {order?.customer?.phoneNumber}
          </b>
        </div>
        <PrintDialog
          open={openPrint}
          testing={order?.testing}
          order={order}
          reTestDate={reTestDate}
          onClose={handleClosePrint}
        />
        <StyledConfirmOrder>
          <Grid container spacing={3}>
            <Grid item md={12} lg={6}>
              <TestResult
                diagnose={order?.testing?.diagnose}
                testing={order?.testing}
                prescription={order?.testing?.prescription}
                reTestDate={reTestDate}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Invoice
                products={order?.products}
                discountAll={order?.discount}
              />
            </Grid>
          </Grid>
        </StyledConfirmOrder>
      </StyledOrderDetail>
      <UpdateOrderDialog
        open={openEditing}
        order={order}
        onClose={() => setOpenEditing(false)}
        onUpdateOrderSuccess={handleUpdatedOrderSuccess}
      />
      <UpdateTestingDialog
        open={openExamination}
        order={order}
        reTestDate={reTestDate}
        onClose={() => setOpenExamination(false)}
        onUpdateOrderSuccess={handleUpdatedOrderSuccess}
      />
    </PageFrame>
  );
};

export default OrderDetail;
