import { Delete } from '@mui/icons-material';
import { IconButton, InputBase, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import {
  CustomTable,
  InputControl,
  StatusCard,
  productTypeCards,
} from '@src/components';
import { useDispatch } from 'react-redux';
import { delimitNumber } from '@src/utils';
import actions from '@src/redux/actions';
import { calculateTotalInvoice } from '@src/services/order';
import { StyledProductOrder } from './index.style';

const ProductOrder = ({
  products = [],
  discountAll,
  setDiscountAll,
  onRemove,
  onChange,
}) => {
  const dispatch = useDispatch();

  const handleChange = (prod, target) => {
    if (target.value.includes('-')) return;
    if (
      target.name === 'quantity' &&
      Number(target.value) > prod.remainQuantity
    ) {
      dispatch(
        actions.noti.push({
          message: 'Kho không đủ hàng!',
          severity: 'warning',
        }),
      );
      return;
    }
    if (target.name === 'discount' && Number(target.value) > 100) {
      dispatch(
        actions.noti.push({
          message: 'Chú ý: chiết khấu đang vượt 100%',
          severity: 'warning',
        }),
      );
    }
    onChange(prod, target);
  };

  const handleRemoveMedicine = (item) => () => {
    onRemove(item);
  };

  const columns = [
    {
      field: 'name',
      align: 'left',
      id: 'name',
      title: 'Tên sản phẩm',
      style: { width: '35%' },
    },
    {
      field: 'type',
      align: 'left',
      id: 'type',
      title: 'Phân loại',
      style: { width: '13%' },
      render: (item) => <StatusCard {...productTypeCards[item.type]} />,
    },
    {
      field: 'quantity',
      align: 'left',
      id: 'quantity',
      title: 'Số lượng',
      style: { width: '15%' },
      render: (item) => (
        <InputBase
          placeholder="0"
          type="number"
          name="quantity"
          value={item.quantity}
          onChange={(event) => handleChange(item, event.target)}
          className="quantity-input"
        />
      ),
    },
    {
      field: 'priceOut',
      align: 'left',
      id: 'priceOut',
      title: 'Giá',
      style: { width: '20%' },
      render: (item) => delimitNumber(item.priceOut),
    },
    {
      field: 'discount',
      align: 'left',
      id: 'discount',
      title: 'Chiết khấu',
      style: { width: '15%' },
      render: (item) => (
        <InputBase
          placeholder="0"
          type="number"
          name="discount"
          value={item.discount}
          onChange={(event) => handleChange(item, event.target)}
          className="quantity-input"
        />
      ),
    },
    {
      field: 'action',
      align: 'right',
      id: 'action',
      title: '',
      render: (item) => (
        <IconButton onClick={handleRemoveMedicine(item.id)}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  const calculation = useMemo(
    () => calculateTotalInvoice(products, discountAll),
    [discountAll, products],
  );

  return (
    <StyledProductOrder>
      <CustomTable showNumber page={1} columns={columns} data={products} />

      <div className="calculation">
        <InputControl
          label="Chiết khấu"
          placeholder="Nhập chiết khấu cho toàn đơn"
          size="small"
          className="input-item"
          value={discountAll}
          type="number"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          onChange={(e) => setDiscountAll(e.target.value)}
        />

        <div className="text-group">
          <Typography>
            <strong>Tổng:</strong> {delimitNumber(calculation.total)}
            <strong> đ</strong>
          </Typography>
          <Typography>
            <strong>Giảm giá:</strong> {delimitNumber(calculation.reduction)}
            <strong> đ</strong>
          </Typography>
          <Typography>
            <strong>Thanh toán:</strong> {delimitNumber(calculation.mustPay)}
            <strong> đ</strong>
          </Typography>
        </div>
      </div>
    </StyledProductOrder>
  );
};

export default ProductOrder;
