import React from 'react';
import { convertDateToVietnameseText } from '@src/utils';
import { StyledPrintOrder } from './index.style';
import Header from './Header';

const PrintOrder = React.forwardRef(
  ({ customer, date, title, children }, ref) => (
    <StyledPrintOrder ref={ref}>
      <Header title={title} customer={customer} />
      {children}
      <div className="footer-print">
        <p>{convertDateToVietnameseText(date)}</p>
      </div>
    </StyledPrintOrder>
  ),
);

export default PrintOrder;
