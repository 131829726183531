import actions from '@src/redux/actions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACCESS_TOKEN_KEY } from '@src/constants';
import { IconButton, MenuItem, Typography } from '@mui/material';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { LogoutOutlined, Menu as MenuIcon } from '@mui/icons-material';
import apis from '@src/apis';
import { setCookie } from '@src/utils';
import NotificationRetestDate from '@src/components/NotificationRetestDate';

import { StyledHeader, StyledMenu } from './index.style';

const NavBar = ({ openSidebar, handleOpenSidebar }) => {
  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [anchorProfile, setAnchorProfile] = useState(null);

  const handleClickProfile = (event) => setAnchorProfile(event.currentTarget);

  const handleLogout = async () => {
    await apis.auth.logout();
    setCookie(ACCESS_TOKEN_KEY, null);
    dispatch(actions.auth.logout());
  };

  return (
    <StyledHeader
      display="flex"
      alignItems="center"
      justifyContent={!openSidebar ? 'space-between' : 'flex-end'}
    >
      {!openSidebar && (
        <IconButton color="secondary" onClick={handleOpenSidebar}>
          <MenuIcon />
        </IconButton>
      )}
      <div className="notification-reTestDate">
        <NotificationRetestDate />
      </div>
      <div className="left-group">
        <div className="profile-content">
          <Typography className="username">{account?.username}</Typography>
          <Typography>
            {account?.facility?.name} - {account?.facility?.address}
          </Typography>
        </div>
        <IconButton onClick={handleClickProfile}>
          <BadgeAvatar
            active
            name={account?.username}
            number={new Date(account?.createdAt)}
          />
        </IconButton>
      </div>
      <StyledMenu
        anchorEl={anchorProfile}
        open={Boolean(anchorProfile)}
        onClose={() => setAnchorProfile(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          classes={{ root: 'menu-item logout-style' }}
          onClick={handleLogout}
        >
          <LogoutOutlined />
          Đăng xuất
        </MenuItem>
      </StyledMenu>
    </StyledHeader>
  );
};

export default NavBar;
