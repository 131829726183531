import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { ArrowBack, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getCollapseStates,
  getCollapseInitialState,
} from '@src/services/sidebar';
// import ROUTES from '@src/constants/route';
import appRoutes from '@src/router/appRoutes';

import { StyledSidebar, StyledDrawer } from './index.style';

const Logo = () => (
  <div className="logo">
    <img src="/images/logo.png" alt="eye clinic logo" className="logo-img" />
  </div>
);

const Sidebar = ({ openSidebar, handleCloseSidebar }) => {
  const [collapseState, setCollapseState] = useState({});

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setCollapseState(getCollapseStates(appRoutes));
  }, []);

  const activeRoute = (routePath) =>
    matchPath(location.pathname, { path: routePath, exact: true });

  const sidebarRoute = appRoutes.filter((route) => route.sidebar);

  const createLinks = (aRoutes) =>
    aRoutes.map((route) => {
      if (route.collapse) {
        const collapse = {};
        collapse[route.name] = !collapseState[route.name];

        return (
          <ListItem key={route.name} className="item">
            <NavLink
              to="#"
              className={classNames('item-link', {
                'collapse-active': getCollapseInitialState(route.items),
              })}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState(collapse);
              }}
            >
              <route.icon className="item-icon" />
              <ListItemText
                primary={t(route.name)}
                secondary={
                  collapseState[route.name] ? (
                    <ArrowDropUp className="caret" />
                  ) : (
                    <ArrowDropDown className="caret" />
                  )
                }
                disableTypography
                className="item"
              />
            </NavLink>
            <Collapse in={collapseState[route.state]} unmountOnExit>
              <List className="list collapse-list">
                {createLinks(route.items)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      return (
        <ListItem key={route.name} className="item">
          <NavLink
            to={route.path}
            className={classNames('item-link', {
              'active-link': activeRoute(route.path),
            })}
          >
            <route.icon className="item-icon" />
            <ListItemText
              primary={t(route.name)}
              disableTypography
              className="item-text"
            />
          </NavLink>
        </ListItem>
      );
    });

  const renderSideBarItems = () => (
    <div className="sidebar-wrapper">
      <List className="list">{createLinks(sidebarRoute)}</List>
      <Divider sx={{ my: '28px', mx: 'auto', width: '80%' }} />
    </div>
  );

  return (
    <>
      <StyledDrawer
        sx={{ display: { md: 'none', xs: 'block' } }}
        variant="temporary"
        anchor="right"
        open={openSidebar}
        onClose={handleCloseSidebar}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledSidebar>
          <div className="brand">
            <Logo />
          </div>
        </StyledSidebar>
      </StyledDrawer>

      <StyledDrawer
        sx={{ display: { md: 'block', xs: 'none' } }}
        variant="persistent"
        anchor="left"
        open={openSidebar}
      >
        <StyledSidebar>
          <div className="brand">
            <Logo />
            <IconButton color="secondary" onClick={handleCloseSidebar}>
              <ArrowBack />
            </IconButton>
          </div>
          {renderSideBarItems()}
        </StyledSidebar>
      </StyledDrawer>
    </>
  );
};

export default Sidebar;
