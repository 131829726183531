import React, { useState } from 'react';
import { BaseDialog } from '@src/components';
import { Box, Tab, Tabs } from '@mui/material';
import tabComponents, { UPDATE_TESTING_TAB_KEYS } from './tabComponents';
import Examination from './Examination';
import Product from './Product';

const UpdateTestingDialog = ({
  open,
  order,
  reTestDate,
  onClose,
  onUpdateOrderSuccess,
}) => {
  const [activeTab, setActiveTab] = useState(tabComponents[0].value);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Cập nhật đơn khám"
      maxWidth="100%"
    >
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabComponents.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {activeTab === UPDATE_TESTING_TAB_KEYS.EXAMINATION ? (
        <Examination
          testing={order?.testing}
          onClose={onClose}
          onUpdateOrderSuccess={onUpdateOrderSuccess}
        />
      ) : null}
      {activeTab === UPDATE_TESTING_TAB_KEYS.PRODUCT ? (
        <Product
          testing={order?.testing}
          reTestDate={reTestDate}
          onClose={onClose}
          onUpdateOrderSuccess={onUpdateOrderSuccess}
        />
      ) : null}
    </BaseDialog>
  );
};

export default UpdateTestingDialog;
