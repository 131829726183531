import { styled } from '@mui/material/styles';

export const StyledCard = styled('div')`
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(30, 30, 30, 0.05);
`;

export const StyleTitle = styled('div')`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  border-bottom: 1px solid #f3f4f6;
  margin-bottom: 24px;
  padding-bottom: 8px;
`;
