import { COLOR } from '@src/styles/color';

import { styled, Menu, Drawer, Box } from '@mui/material';
import { BORDER_RADIUS, BOX_SHADOW, SIDEBAR_WIDTH } from '@src/styles/config';

export const StyledWrapper = styled('div')`
  position: relative;
  top: 0;
  height: 100vh;

  &:after {
    display: table;
    clear: both;
    content: '';
  }
`;

export const StyledMainPanel = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '&.open-sidebar': { width: `calc(100% - ${SIDEBAR_WIDTH}px)` },
  },
  paddingLeft: '12px',
  overflow: 'auto',
  position: 'relative',
  float: 'right',
  height: '100%',
  width: '100%',
}));

export const StyledHeader = styled(Box)`
  width: 100%;
  background-color: ${COLOR.white};
  box-shadow: ${BOX_SHADOW};
  border-radius: 5px;
  justify-content: space-between;

  .notification-reTestDate {
    margin-left: 24px;
  }

  .left-group {
    display: flex;
    align-items: center;
  }

  .profile-content {
    .username {
      font-weight: bold;
      text-align: right;
    }
  }
`;

export const StyledContent = styled('div')`
  min-width: 600px;
  flex-grow: 1;
  padding: 16px;
  margin-top: 9px;
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: ${BOX_SHADOW};
  background: white;

  .banner {
    width: 100%;
  }

  .content-layout {
    flex-grow: 1;
  }
`;

export const StyledMenu = styled(Menu)`
  .menu-item {
    grid-gap: 10px;
  }

  .text-link {
    text-decoration: none;
  }

  .logout-style {
    color: ${COLOR.danger};
  }
  .style-version {
    margin-left: 20px;
    color: ${COLOR.light};
  }
`;

export const StyledSidebar = styled('div')`
  height: 100%;
  position: relative;

  .brand {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
    .logo {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
    .logo-img {
      cursor: pointer;
      width: 144px;
    }
  }

  .sidebar-wrapper {
    position: relative;
    overflow: auto;
    width: ${SIDEBAR_WIDTH};
    z-index: 4;
    color: inherit;
    padding-bottom: 30px;
  }

  .list {
    margin-top: 6px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    list-style: none;
    color: inherit;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .collapse-list {
    margin-top: 0;

    &.caret {
      margin-top: 8px;
    }
  }

  .collapse-active {
    outline: none;
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: none;
  }

  .item {
    color: inherit;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  .item-link {
    margin: 6px 10px 0;
    border-radius: ${BORDER_RADIUS};
    position: relative;
    display: block;
    padding: 6px 8px;
    width: auto;
    text-decoration: none;
    line-height: 30px;

    &:hover {
      outline: none;
      background-color: ${({ theme }) => theme.palette.transparentColor.main};
      box-shadow: none;
    }

    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .item-icon {
    color: inherit;
    width: 30px;
    height: 24px;
    float: left;
    position: inherit;
    top: 3px;
    margin-right: 15px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }

  .item-text {
    color: inherit;
    margin: 0;
    line-height: 30px;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
    padding: 0 16px !important;
  }

  .caret {
    margin-top: 4px;
    position: absolute;
    right: 18px;
  }

  .active-link {
    &,
    &:hover,
    &:focus {
      color: ${COLOR.white};
      background-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: ${BOX_SHADOW};
    }
  }

  .convert-ole-version-button {
    margin: 10px 15px 0;
    border-radius: 5px;
    padding: 10px 15px;
    min-width: 225px;
  }
`;

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    boxShadow: BOX_SHADOW,
    width: SIDEBAR_WIDTH,

    [theme.breakpoints.up('md')]: {
      width: SIDEBAR_WIDTH,
      position: 'fixed',
      height: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      width: SIDEBAR_WIDTH,
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },

    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
}));
