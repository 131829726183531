import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const SingleGlass = ({ indicators }) => (
  <div className="glass-info">
    <Typography className="prescription-title">ĐƠN KÍNH</Typography>
    <Table sx={{ borderCollapse: 'collapse' }} aria-label="Khong Kinh">
      <TableHead>
        <TableRow>
          <TableCell
            className="border header"
            sx={{ width: '8%' }}
            align="center"
          >
            Mắt
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '15%' }}
            align="center"
          >
            Kính cũ
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '8%' }}
            align="center"
          >
            ADD
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '10%' }}
            align="center"
          >
            Thị lực
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '15%' }}
            align="center"
          >
            Kính mới
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '8%' }}
            align="center"
          >
            ADD
          </TableCell>
          <TableCell
            className="border header"
            sx={{ width: '10%' }}
            align="center"
          >
            Thị lực
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            className="border"
            component="th"
            scope="row"
            align="center"
          >
            Phải
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.oldMP}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.oldADD}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.oldVA1}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.newMP}
          </TableCell>
          <TableCell className="border" align="center" rowSpan={2}>
            {indicators?.newADD}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.newVA1}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className="border"
            component="th"
            scope="row"
            align="center"
          >
            Trái
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.oldMT}
          </TableCell>

          <TableCell className="border" align="center">
            {indicators?.oldVA2}
          </TableCell>
          <TableCell className="border" align="center">
            {indicators?.newMT}
          </TableCell>

          <TableCell className="border" align="center">
            {indicators?.newVA2}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

export default SingleGlass;
