const { styled } = require('@mui/material');

export const StyledChangePassword = styled('div')`
  .item-input {
    margin-bottom: 26px;
    .input {
      width: 100%;
    }
  }
`;
