import React, { useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  calculateAge,
  convertFormatDate,
  getStartAndEndOfDay,
} from '@src/utils';
import apis from '@src/apis';
import { ProcessHandler } from '../common';

import { StyledNotificationRetestDate, StyledPopover } from './index.style';

const NotificationRetestDate = () => {
  const [open, setOpen] = useState(false);
  const [reTestDates, setReTestDates] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const fetchRetestDates = async () => {
    const { startDate, endDate } = getStartAndEndOfDay(new Date(), 3);

    setLoading(true);
    const res = await apis.retestDates.getReTestDates({
      startDate,
      endDate,
      dateField: 'date',
    });
    setLoading(false);

    if (!res?.status) return;

    setReTestDates(res.result.reTestDates);
  };

  useEffect(() => {
    fetchRetestDates();
  }, []);

  return (
    <StyledNotificationRetestDate>
      <div
        className="button-icon"
        onClick={handleOpen}
        role="button"
        tabIndex="0"
      >
        <NotificationsIcon />
        <div className="quantity-notification">{reTestDates.length}</div>
      </div>
      {open && (
        <Popover
          id="simple-popover"
          open={open}
          onClose={handleClose}
          anchorEl={document.querySelector('.button-icon')}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <StyledPopover>
            <Typography sx={{ p: 2 }} className="header">
              <b>
                Danh sách tái khám từ {convertFormatDate(new Date(), 'DD-MM')}{' '}
                đến{' '}
                {convertFormatDate(
                  new Date().setDate(new Date().getDate() + 3),
                  'DD-MM',
                )}
              </b>
            </Typography>
            <Divider />
            <List className="reTestDate-list">
              <ProcessHandler loading={loading} align="center" mt="30px">
                {reTestDates.length &&
                  reTestDates.map((reTestDate) => (
                    <ListItem disablePadding className="reTestDate-item">
                      <ListItemText
                        primary={
                          <div className="main-info">
                            <b>
                              Tên: {reTestDate?.customer?.name} -{' '}
                              {reTestDate?.customer?.phoneNumber}
                            </b>
                            <div className="reTestDate">
                              <b>
                                {convertFormatDate(reTestDate.date, 'DD-MM')}
                              </b>
                            </div>
                          </div>
                        }
                        secondary={
                          <div className="secondary-info">
                            <Typography>
                              Tuổi:{' '}
                              {calculateAge(reTestDate?.customer?.birthday)}
                            </Typography>
                            <Typography>
                              Ngày sinh:{' '}
                              {convertFormatDate(
                                reTestDate?.customer?.birthday,
                                'DD-MM-YYYY',
                              )}
                            </Typography>
                            <Typography>
                              Địa chỉ: {reTestDate?.customer?.address}
                            </Typography>
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
              </ProcessHandler>
            </List>
          </StyledPopover>
        </Popover>
      )}
    </StyledNotificationRetestDate>
  );
};

export default NotificationRetestDate;
