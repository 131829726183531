import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BaseDialog, ProcessHandler } from '@src/components';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { StyledButtonGroup } from './index.style';

const CancelTestingDialog = ({ testingId, onClose, onCanceledSuccess }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    const res = await apis.testings.cancelTesting(testingId);
    setLoading(false);
    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Xoá đơn khám thành công',
        severity: 'success',
      }),
    );
    onCanceledSuccess(testingId);
  };

  return (
    <BaseDialog open={!!testingId} onClose={onClose} title="Xoá đơn khám">
      <Box padding="12px">
        <Typography>
          Khách hàng này sẽ bị xoá ra khỏi hàng đợi khám bệnh ngay lập tức!
        </Typography>
        <Typography>
          <strong>
            Bạn có chắn chắn muốn xoá đơn khám của khách hàng này?
          </strong>
        </Typography>

        <StyledButtonGroup>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Hủy
          </Button>
          <Button size="small" onClick={handleSubmit} variant="contained">
            <ProcessHandler size={25} loading={loading}>
              Xoá
            </ProcessHandler>
          </Button>
        </StyledButtonGroup>
      </Box>
    </BaseDialog>
  );
};

export default CancelTestingDialog;
