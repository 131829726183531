import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { ProcessHandler } from '@src/components';
import { ERROR_CODE } from '@src/errors/code';
import { TESTING_STEP } from '@src/constants';

import { StyledBtnGroup, StyledConfirmOrder } from './index.style';
import TestResult from './TestResult/index';
import Invoice from './Invoice';
import PrintDialog from './PrintOrder/PrintDialog';

const ConfirmOrderStep = ({
  testing,
  order,
  reTestDate,
  onEndTest,
  onBackStep,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({ confirm: false, cancel: false });
  const [openPrintOption, setOpenPrintOption] = useState(false);

  const handleErrorConfirmOrder = (code = 0, message = '') => {
    if (code === ERROR_CODE.RUN_OUT_PRODUCT) {
      const prod = order.products.find((item) => item.product.id === message);
      if (prod) {
        dispatch(
          actions.noti.push({
            message: `Sản phẩm ${prod.product?.name} đã hết hàng!`,
            severity: 'error',
          }),
        );
        return;
      }
    }
    dispatch(actions.noti.push({ code: code || 0, severity: 'error' }));
  };

  const confirmOrder = async () => {
    setLoading({ ...loading, confirm: true });
    const res = await apis.orders.confirmOrder(order.id);
    setLoading({ ...loading, confirm: false });

    if (!res?.status) {
      handleErrorConfirmOrder(res?.code, res?.message);
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Xác nhận thanh toán thành công',
        severity: 'success',
      }),
    );
  };

  const handleConfirmAndPrintOrder = async () => {
    await confirmOrder();
    setOpenPrintOption(true);
  };

  const handleCancelOrder = async () => {
    setLoading({ ...loading, cancel: true });
    const res = await apis.orders.cancelOrder(order.id);
    setLoading({ ...loading, cancel: false });

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Xác nhận hủy đơn thành công',
        severity: 'success',
      }),
    );
    onEndTest(order.customer?.id);
  };

  const handleClosePrintOption = () => {
    setOpenPrintOption(false);
    onEndTest(testing.customer?.id);
  };

  return (
    <StyledConfirmOrder>
      <Grid container spacing={3}>
        <Grid item md={12} lg={6}>
          <TestResult
            testing={testing}
            diagnose={testing?.diagnose}
            reTestDate={reTestDate}
            prescription={testing?.prescription}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <Invoice products={order?.products} discountAll={order?.discount} />
        </Grid>
      </Grid>
      <StyledBtnGroup>
        <Button variant="contained" onClick={handleConfirmAndPrintOrder}>
          <ProcessHandler size={25} loading={loading.confirm}>
            Xác nhận và In
          </ProcessHandler>
        </Button>
        <Button
          variant="outlined"
          onClick={() => onBackStep(TESTING_STEP.GLASS_MEDICINE_SERVICE)}
        >
          Quay lại
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelOrder}
        >
          <ProcessHandler size={25} loading={loading.cancel}>
            Hủy đơn
          </ProcessHandler>
        </Button>
      </StyledBtnGroup>
      <PrintDialog
        open={openPrintOption}
        testing={testing}
        order={order}
        reTestDate={reTestDate}
        onClose={handleClosePrintOption}
      />
    </StyledConfirmOrder>
  );
};

export default ConfirmOrderStep;
