import SettingChat from '@src/components/SettingChat';
import ChangePassword from '@src/components/ChangePassword';
import GeneralSettings from './GeneralSettings';

export default [
  {
    value: 'generalSetting',
    label: 'Cài đặt chung',
    component: GeneralSettings,
  },
  {
    value: 'settingChat',
    label: 'Cài đặt thông báo',
    component: SettingChat,
  },
  {
    value: 'changePassword',
    label: 'Đổi mật khẩu',
    component: ChangePassword,
  },
];
