import React from 'react';
import { Typography } from '@mui/material';

import { StyledPageFrame } from './index.style';

const PageFrame = ({ title, children }) => (
  <StyledPageFrame>
    <Typography className="title">{title}</Typography>
    <div className="content">{children}</div>
  </StyledPageFrame>
);

export { PageFrame };
