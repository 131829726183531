import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from '@src/constants/route';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <Redirect to={ROUTES.RECEPTIONIST} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
