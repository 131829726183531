const { styled } = require('@mui/material');

export const StyledProduct = styled('div')`
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .options {
      display: flex;
      align-items: center;
    }
  }

  .create-product {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .list-filter {
    display: flex;

    .item-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 12px;

      .select-option {
        width: 130px;
      }

      p {
        font-size: 16px;
        margin-right: 12px;
      }
    }
  }

  .search {
    align-self: end;

    .input-search {
      margin-right: 12px;
    }
  }
`;

export const StyledCreateProduct = styled('div')`
  padding: 12px;

  .description {
    padding-top: 0px !important;
  }

  .control-item {
    margin: 12px 0;
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }

    p:first-of-type {
      margin-bottom: 3px;
    }

    .req {
      color: red;
    }

    .MuiTextField-root {
      width: 100%;
    }

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  .button-group {
    width: 100%;
    display: flex;
    gap: 6px;
    justify-content: flex-end;
  }
`;
