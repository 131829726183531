import { COLOR } from './color';

const SIDEBAR_WIDTH = 230;

const BOX_SHADOW = '0px 4px 24px rgba(0, 0, 0, 0.06)';

const TOOLTIP_BOX_SHADOW = '0px 4px 15px 0px #2C3F5859';

const BORDER_RADIUS = '5px';

const AVATAR_COLORS = [
  COLOR.primary,
  COLOR.success,
  COLOR.purple,
  COLOR.danger,
  COLOR.orange,
  COLOR.darkGreen,
];

export {
  SIDEBAR_WIDTH,
  BOX_SHADOW,
  BORDER_RADIUS,
  AVATAR_COLORS,
  TOOLTIP_BOX_SHADOW,
};
