import api from './api';

const createChatId = async (chatId) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/accounts/telegram/add-chat-id',
      data: { chatId },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const switchNotify = async () => {
  try {
    const res = await api({
      method: 'PUT',
      url: '/accounts/telegram/toggle-notification',
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export { createChatId, switchNotify };
