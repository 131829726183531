import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import apis from '@src/apis';
import { PRODUCT_TYPE } from '@src/constants';
import { ProcessHandler } from '@src/components';
import AddIcon from '@mui/icons-material/Add';
import { debounce } from '@src/utils';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';

import { StyledSearchMedicine } from './index.style';

const SearchMedicine = ({ onPickMedicine }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [otherMedicine, setOtherMedicine] = useState('');

  const dispatch = useDispatch();

  const fetchMedicine = async () => {
    setLoading(true);
    const res = await apis.products.getProducts({
      type: PRODUCT_TYPE.MEDICINE,
      limit: 5,
      page: 1,
      status: true,
      search,
      searchFields: 'name',
    });
    setLoading(false);

    if (!res?.status) return;

    setMedicines(res.result.products);
  };

  const handlePickMedicine = (medicine) => () => {
    onPickMedicine(medicine);
  };

  const handlePickOtherMedicine = () => {
    if (!otherMedicine) {
      dispatch(
        actions.noti.push({
          message: 'Vui Lòng nhập tên thuốc',
          severity: 'error',
        }),
      );
      return;
    }
    onPickMedicine({ id: null, name: otherMedicine });
    setOtherMedicine('');
  };

  useEffect(() => {
    debounce(fetchMedicine, 500)();
  }, [search]);

  return (
    <StyledSearchMedicine>
      <Typography className="title-med">Chọn thuốc</Typography>
      <TextField
        placeholder="Tìm kiếm tên thuốc"
        size="small"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <List className="medicine-list">
        <ProcessHandler loading={loading} align="center" mt="20px">
          {medicines.length &&
            medicines.map((med) => (
              <ListItem
                key={med.id}
                disablePadding
                className="medicine-item"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="add-icon"
                    onClick={handlePickMedicine(med)}
                  >
                    <AddIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={med.name}
                  secondary={`SL: ${med.quantity}, CK: ${med.discount || 0}%`}
                />
              </ListItem>
            ))}
        </ProcessHandler>
      </List>
      <div className="other-medicine">
        <TextField
          size="small"
          placeholder="Nhập tên thuốc ngoài phòng khám"
          className="other-medicine-input"
          value={otherMedicine}
          onChange={(event) => setOtherMedicine(event.target.value)}
        />
        <Button
          variant="outlined"
          size="small"
          onClick={handlePickOtherMedicine}
        >
          Thêm
        </Button>
      </div>
    </StyledSearchMedicine>
  );
};

export default SearchMedicine;
