const { styled } = require('@mui/material');

export const StyledCustomer = styled('div')`
  .container {
    display: grid;
    grid-template-columns: 300px 400px;
    grid-template-rows: repeat(4, auto);
    gap: 10px 50px;
  }

  .item {
    padding: 10px;
  }

  .diagnose {
    max-width: 350px;
  }
`;
