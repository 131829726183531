import { COLOR } from '@src/styles/color';

const { styled } = require('@mui/material');

export const StyledNotificationRetestDate = styled('div')`
  .button-icon {
    position: relative;
    cursor: pointer;

    .quantity-notification {
      position: absolute;
      top: 0;
      right: 0;
      background-color: ${COLOR.danger};
      padding: 0 5px;
      font-size: 12px;
      border-radius: 50%;
      color: ${COLOR.white};
      margin-right: -4px;
    }

    svg {
      font-size: 32px;
    }
  }
  }
`;

export const StyledPopover = styled('div')`
  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }

  .reTestDate-list {
    width: 500px;
    max-height: 470px;
    

    .reTestDate-item {
      padding: 0 24px;
    }

    .main-info {
      display: flex;
      justify-content: space-between;
    }
`;
