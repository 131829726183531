import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledChartWrapper = styled(Box)`
  background-color: ${COLOR.white};
`;

export const StyledBarChartWrapper = styled(Box)`
  background-color: ${COLOR.white};
  height: 400px;
`;
