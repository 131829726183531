import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from '@src/constants/route';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? <Component {...props} /> : <Redirect to={ROUTES.LOGIN} />
      }
    />
  );
};

export default PrivateRoute;
