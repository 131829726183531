import api from './api';

const getTestQueues = async ({ sort = 'createdAt_asc' }) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/test-queues',
      params: {
        sort,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const createTestQueue = async (customerId) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/test-queues',
      data: { customerId },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const removeTestQueue = async (testQueueId) => {
  try {
    const res = await api({
      method: 'DELETE',
      url: `/test-queues/${testQueueId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export { getTestQueues, createTestQueue, removeTestQueue };
