import React from 'react';
import { StyledContent } from './index.style';

const Content = ({ children, openSidebar }) => (
  <StyledContent openSidebar={openSidebar}>
    <div className="content-layout">{children}</div>
  </StyledContent>
);

export default Content;
