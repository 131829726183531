import React from 'react';
import { Typography } from '@mui/material';
import { COLOR_SETS } from '@src/styles/color';

import { StyledItemColorSetSelect, StyledSelectColorSet } from './index.style';

const SelectColorSet = ({ colorSetActive, onSelect }) => {
  const handleChooseColorSet = (colorSet) => {
    onSelect(colorSet);
  };

  return (
    <StyledSelectColorSet>
      <Typography>Chọn màu chủ đạo</Typography>
      <div className="list-color-select">
        {COLOR_SETS.map((colorSet) => (
          <StyledItemColorSetSelect
            key={colorSet.primary}
            active={colorSetActive === colorSet.primary}
            style={{ backgroundColor: colorSet.primary }}
            onClick={() => {
              handleChooseColorSet(colorSet);
            }}
          />
        ))}
      </div>
    </StyledSelectColorSet>
  );
};

export default SelectColorSet;
