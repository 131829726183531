import { combineReducers } from 'redux';
import auth, { initialState as authInitialState } from './auth/reducer';
import theme, { initialState as themeInitialState } from './theme/reducer';
import noti, { initialState as notiInitialState } from './noti/reducer';

export const initialState = {
  auth: authInitialState,
  noti: notiInitialState,
  theme: themeInitialState,
};

export default combineReducers({
  auth,
  noti,
  theme,
});
