import { COLOR } from '@src/styles/color';

const { styled } = require('@mui/material');

export const StyledServiceStep = styled('div')`
  padding-top: 10px;

  .title-service {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0;
  }
`;

export const StyledSearchProduct = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 12px;

  .product-list {
    margin-bottom: 10px;
    border-top: 1px solid ${COLOR.divider};
    border-radius: 5px;

    .medicine-item {
      border-bottom: 1px solid ${COLOR.divider};
    }
  }

  .other-medicine {
    display: flex;
    gap: 5px;
    align-items: center;

    .other-medicine-input {
      flex-grow: 1;
    }
  }
`;

export const StyledProductOrder = styled('div')`
  display: flex;
  flex-direction: column;
  height: 470px;
  padding: 12px 0;
  overflow: auto;
  justify-content: space-between;

  .calculation {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .input-item {
      width: 330px;
    }

    .text-group {
    }
  }
`;
