const { styled } = require('@mui/material');

export const StyledOrderDetail = styled('div')`
  .info-customer {
    margin: 18px 0 12px 0;
  }
`;

export const StyledButtonGroup = styled('div')`
  margin-top: 6px;
  margin-bottom: 12px;
  display: flex;
  gap: 6px;
`;

export const StyledUpdateTestingDialog = styled('div')`
  margin: 12px;

  .diagnose {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .groupButton {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
  }
`;

export const StyledUpdateTestingButtonGroup = styled('div')`
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  margin: 6px 12px 12px 6px;
`;
