import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BaseDialog, ProcessHandler } from '@src/components';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { ORDER_STATUS } from '@src/constants';

import { StyledDeleteOrder } from './index.style';

const DeleteOrderDialog = ({
  open,
  customerName,
  orderStatus,
  orderId,
  onClose,
  onDeletedSuccess,
}) => {
  const [revokeQuantity, setRevokeQuantity] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChangeCheckBox = (event) => {
    setRevokeQuantity(event.target.checked);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await apis.orders.deleteOrder(orderId, revokeQuantity);
    setLoading(false);
    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res.message,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Xoá đơn hàng thành công',
        severity: 'success',
      }),
    );
    onDeletedSuccess(orderId);
  };

  return (
    <BaseDialog open={open} onClose={onClose} title="Xoá đơn hàng">
      <StyledDeleteOrder>
        <Typography>
          <span>Bạn có chắn chắn muốn xoá đơn hàng của khách hàng</span>
          <span> </span>
          <strong>{customerName}</strong>?
        </Typography>
        {orderStatus === ORDER_STATUS.PAID && (
          <FormControlLabel
            control={
              <Checkbox
                checked={revokeQuantity}
                name="revokeQuantity"
                onChange={handleChangeCheckBox}
              />
            }
            label="Thu hồi sản phẩm về kho"
          />
        )}

        <div className="btn-group">
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Hủy
          </Button>
          <Button size="small" onClick={handleSubmit} variant="contained">
            <ProcessHandler size={25} loading={loading}>
              Xoá
            </ProcessHandler>
          </Button>
        </div>
      </StyledDeleteOrder>
    </BaseDialog>
  );
};

export default DeleteOrderDialog;
