import React from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ThemeWrapper from './containers/layout/ThemeWrapper';
import store from './redux/store';
import AppRouter from './router';
import NotiHandler from './errors/NotiHandler';

const notistackRef = React.createRef();

const App = () => {
  console.log('app run');

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store()}>
        <CssBaseline />
        <ThemeWrapper>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            ref={notistackRef}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <NotiHandler>
              <AppRouter />
            </NotiHandler>
          </SnackbarProvider>
        </ThemeWrapper>
      </Provider>
    </LocalizationProvider>
  );
};

export default App;
