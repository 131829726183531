import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { StyledSetting } from './index.style';
import tabComponents from './tabComponents';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(tabComponents[0].value);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <StyledSetting>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabComponents.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      {tabComponents.map((tab) =>
        activeTab === tab.value ? <tab.component key={tab.value} /> : null,
      )}
    </StyledSetting>
  );
};

export default Settings;
