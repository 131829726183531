import { COLOR } from '@src/styles/color';

const { styled } = require('@mui/material');

export const StyledSettingChat = styled('div')`
  .tutorials {
    opacity: 0.6;
    font-size: 14px;
    margin: 20px 0;
  }

  .back-switch-text {
    color: ${COLOR.danger};
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
  }

  .image-container {
    text-align: center;

    .image-qr-code {
      width: 65%;
      max-width: 250px;
      min-width: 130px;
    }

    .text {
      font-size: 12px;
      opacity: 0.6;
    }
  }
`;

export const StyledSwitchNotify = styled('div')`
  .edit-chatID {
    color: ${COLOR.danger};
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
  }

  .switch-input {
    .MuiSwitch-thumb {
      box-shadow: '0px 2px 2px rgba(0, 0, 0, 0.15)';
    }
    .MuiSwitch-track {
      border-radius: 13.5px;
    }
  }
`;
