import { PAGINATION_LIMIT } from '@src/constants';
import api from './api';

const getProducts = async ({
  limit = PAGINATION_LIMIT,
  page = 1,
  search = '',
  sort = 'createdAt_desc',
  fields,
  searchFields,
  status,
  type,
}) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/products',
      params: {
        limit,
        offset: (page - 1) * PAGINATION_LIMIT,
        search,
        fields,
        sort,
        searchFields,
        status,
        type,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const getProductsByIds = async (productIds = []) => {
  const stringIds = productIds.reduce((prev, curr) => {
    if (!prev) return curr;
    return `${prev},${curr}`;
  }, '');

  try {
    const res = await api({
      method: 'GET',
      url: '/products/by-ids',
      params: {
        productIds: stringIds,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const createProduct = async ({
  name,
  description,
  priceIn,
  priceOut,
  discount,
  quantity,
  type,
}) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/products',
      data: { name, description, priceIn, priceOut, discount, quantity, type },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const removeProduct = async (productId) => {
  try {
    const res = await api({
      method: 'DELETE',
      url: `/products/${productId}`,
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const updateProduct = async ({
  productId,
  name,
  description,
  priceIn,
  priceOut,
  discount,
  quantity,
  type,
}) => {
  try {
    const res = await api({
      method: 'PUT',
      url: `/products/${productId}`,
      data: { name, description, priceIn, priceOut, discount, quantity, type },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};
export {
  getProducts,
  getProductsByIds,
  createProduct,
  updateProduct,
  removeProduct,
};
