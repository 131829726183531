import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { delimitNumber } from '@src/utils';
import { calculateTotalInvoice } from '@src/services/order';
import { StyledInvoicePrint } from './index.style';

const InvoicePrint = ({ products = [], discountAll = 0 }) => {
  const payment = calculateTotalInvoice(products, discountAll);

  return (
    <StyledInvoicePrint>
      <Table sx={{ borderCollapse: 'collapse' }} aria-label="Khong Kinh">
        <TableHead>
          <TableRow>
            <TableCell className="border header" align="center">
              STT
            </TableCell>
            <TableCell className="border header" align="center" width="35%">
              Sản phẩm/Dịch vụ
            </TableCell>
            <TableCell className="border header" align="center">
              Số lượng
            </TableCell>
            <TableCell className="border header" align="center">
              Đơn giá
            </TableCell>
            <TableCell className="border header" align="center">
              Chiết khấu
            </TableCell>
            <TableCell className="border header" align="center">
              Thành tiền
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((item, index) => (
            <TableRow key={item.product?.id}>
              <TableCell
                className="border invoice-cell"
                component="th"
                scope="row"
                align="center"
              >
                {index + 1}
              </TableCell>
              <TableCell className="border invoice-cell" align="center">
                {item.product?.name}
                {}
              </TableCell>
              <TableCell className="border invoice-cell" align="center">
                {item.quantity}
              </TableCell>
              <TableCell className="border invoice-cell" align="center">
                {delimitNumber(item.priceOut)}
              </TableCell>
              <TableCell className="border invoice-cell" align="center">
                {(item.discount || 0) + discountAll}%
              </TableCell>
              <TableCell className="border invoice-cell" align="center">
                {delimitNumber(
                  (item.priceOut -
                    (item.priceOut * (item.discount + discountAll)) / 100) *
                    item.quantity,
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="total-payment">
        <p>Tổng: {delimitNumber(payment?.total)} đ</p>
        <p>Giảm giá: -{delimitNumber(payment?.reduction)} đ</p>
        <p>Thanh toán: {delimitNumber(payment?.mustPay)} đ</p>
      </div>
    </StyledInvoicePrint>
  );
};

export default InvoicePrint;
