import { COLOR } from '@src/styles/color';

const { styled, Box } = require('@mui/material');

export const StyledWaitItem = styled(Box)`
  padding: 4px 4px 4px 12px;
  border: ${({ active }) =>
    !active ? `1px solid ${COLOR.lightDivider}` : 'none'};
  border-radius: 5px;
  width: fit-content;
  align-items: start;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  background: ${({ theme, active }) =>
    active ? theme.palette.primary.main : 'none'};
  ${({ active, padLast }) => !active && padLast && 'padding-right: 12px;'}
  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.palette.primary.main : COLOR.light};
  }

  .no {
    ${({ active }) => (active ? 'color: white;' : '')}
    font-size: 16px;
    font-weight: 600;
  }

  .item-title {
    p:first-of-type {
      font-weight: 600;
      text-align: left;
    }
    p {
      ${({ active }) => (active ? 'color: white;' : '')}
    }
  }

  .active-icon {
    color: white;
  }
`;

export const StyledTestQueue = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .title-queue {
    font-weight: 600;
  }
`;

export const ListWrapper = styled('div')`
  display: flex;
  align-items: start;
  gap: 12px;
  flex-wrap: wrap;
  flex-basis: max-content;

  border: 1px solid ${COLOR.lightDivider};
  border-radius: 5px;
  max-height: 230px;
  padding: 12px;
  overflow: auto;
  margin-bottom: 12px;

  &.no-testQueues {
    display: flex;
    justify-content: space-around;
  }
`;
