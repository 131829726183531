import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { StyledIndicators } from './index.style';

const Indicators = ({ indicators, onChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  return (
    <StyledIndicators>
      <Grid container>
        <Grid item md={12} lg={5}>
          <Typography className="indicator-title" mb="30px">
            Không kính
          </Typography>
          <div className="no-class-indicator">
            <Typography>MP</Typography>
            <TextField
              size="small"
              placeholder="VA"
              name="noMpVa"
              value={indicators.noMpVa}
              onChange={handleChange}
            />
            <TextField
              size="small"
              placeholder="PH"
              name="noMpPh"
              value={indicators.noMpPh}
              onChange={handleChange}
            />
          </div>
          <div className="no-class-indicator">
            <Typography>MT</Typography>
            <TextField
              size="small"
              placeholder="VA"
              name="noMtVa"
              value={indicators.noMtVa}
              onChange={handleChange}
            />
            <TextField
              size="small"
              placeholder="PH"
              name="noMtPh"
              value={indicators.noMtPh}
              onChange={handleChange}
            />
          </div>
          <div className="pd-ct">
            <TextField
              size="small"
              placeholder="PD"
              name="noPD"
              value={indicators.noPD}
              onChange={handleChange}
            />
            <TextField
              size="small"
              placeholder="CT"
              name="noCT"
              value={indicators.noCT}
              onChange={handleChange}
            />
            <TextField
              size="small"
              placeholder="EOM"
              name="noEOM"
              value={indicators.noEOM}
              onChange={handleChange}
            />
            <TextField
              size="small"
              placeholder="NPC"
              name="noNPC"
              value={indicators.noNPC}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item md={0} lg={1} />
        <Grid item md={12} lg={6}>
          <Typography className="indicator-title">Đơn kính</Typography>
          <div className="singer-glass">
            <Typography>Kính cũ</Typography>
            <div className="grid-container">
              <div className="mp">
                <TextField
                  size="small"
                  placeholder="MP"
                  name="oldMP"
                  value={indicators.oldMP}
                  onChange={handleChange}
                />
              </div>
              <div className="mt">
                <TextField
                  size="small"
                  placeholder="MT"
                  name="oldMT"
                  value={indicators.oldMT}
                  onChange={handleChange}
                />
              </div>
              <div className="add">
                <TextField
                  size="small"
                  placeholder="ADD"
                  name="oldADD"
                  value={indicators.oldADDv}
                  onChange={handleChange}
                />
              </div>
              <div className="va1">
                <TextField
                  size="small"
                  placeholder="VA"
                  name="oldVA1"
                  value={indicators.oldVA1}
                  onChange={handleChange}
                />
              </div>
              <div className="va2">
                <TextField
                  size="small"
                  placeholder="VA"
                  name="oldVA2"
                  value={indicators.oldVA2}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="singer-glass">
            <Typography>Kính mới</Typography>
            <div className="grid-container">
              <div className="mp">
                <TextField
                  size="small"
                  placeholder="MP"
                  name="newMP"
                  value={indicators.newMP}
                  onChange={handleChange}
                />
              </div>
              <div className="mt">
                <TextField
                  size="small"
                  placeholder="MT"
                  name="newMT"
                  value={indicators.newMT}
                  onChange={handleChange}
                />
              </div>
              <div className="add">
                <TextField
                  size="small"
                  placeholder="ADD"
                  name="newADD"
                  value={indicators.newADD}
                  onChange={handleChange}
                />
              </div>
              <div className="va1">
                <TextField
                  size="small"
                  placeholder="VA"
                  name="newVA1"
                  value={indicators.newVA1}
                  onChange={handleChange}
                />
              </div>
              <div className="va2">
                <TextField
                  size="small"
                  placeholder="VA"
                  name="newVA2"
                  value={indicators.newVA2}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </StyledIndicators>
  );
};

export default Indicators;
