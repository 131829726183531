import { styled } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledTestStep = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .reason-testing {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .input {
    flex: 1;
    padding-left: 12px;

    input {
      height: 8px;
    }
  }
`;

export const StyledListIndicators = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  border: 1px solid ${COLOR.borderColor};
  border-radius: 6px;
  padding: 0 18px;

  .item-indicators {
    width: 50%;
    display: flex;
    align-items: center;
    margin: 12px 0;
    padding: 0 16px;
  }

  .text {
    flex: 2;
  }

  .list-inputs {
    margin-left: 16px;
    flex: 8;

    .item-input {
      display: flex;
      align-items: center;
      margin: 8px 0;
      width: 80%;

      .inputs {
        flex: 1;
        display: flex;
        justify-content: flex-start;
      }

      .input {
        flex: 1;
      }
    }

    .sub-list-inputs {
      display: flex;
      align-items: center;
      width: 80%;

      .input {
        width: 100%;
      }

      .sub-item-input {
        width: 50%;

        .item-input {
          width: 100%;
        }
      }
      .sub-input {
        width: 50%;
      }
    }
  }
`;

export const StyledIndicators = styled('div')`
  .indicator-title {
    font-weight: 600;
    font-size: 16px;
  }

  .no-class-indicator {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 8px 0;
  }

  .pd-ct {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  .singer-glass {
    margin-bottom: 32px;

    p {
      width: 100%;
      text-align: center;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .mp {
      grid-area: MP;
    }
    .mt {
      grid-area: MT;
    }
    .add {
      grid-area: ADD;
      width: 70px;

      .MuiOutlinedInput-root {
        height: 94px;
      }
    }
    .va1 {
      grid-area: VA1;
    }
    .va2 {
      grid-area: VA2;
    }

    .grid-container {
      display: grid;
      grid-template-areas:
        'MP ADD VA1'
        'MT ADD VA2';
      gap: 10px;
    }
  }
`;
