import Login from '@src/pages/Login';
import { ROUTES } from '@src/constants/route';
import Testing from '@src/pages/Testing';
import Receptionist from '@src/pages/Receptionist';
import Product from '@src/containers/Product';
import Order from '@src/pages/Order';
import {
  Category as ProductIcon,
  LocalHospital as TestingIcon,
  GroupAdd as ReceptionistIcon,
  Article as OrderIcon,
  InsertChart as StatisticIcon,
  Settings as SettingsIcon,
  DateRange as DateRangeIcon,
} from '@mui/icons-material';
import OrderDetail from '@src/pages/OrderDetail';
import Statistic from '@src/pages/Statistic';
import CustomerDetail from '@src/pages/Customer';
import RetestDate from '@src/containers/RetestDate';
import Setting from '@src/pages/Setting';

export default [
  {
    name: 'Lễ Tân',
    path: ROUTES.RECEPTIONIST,
    component: Receptionist,
    exact: true,
    restricted: true,
    isPrivate: true,
    icon: ReceptionistIcon,
    sidebar: true,
  },
  {
    name: 'Khám Bệnh',
    path: ROUTES.TESTING,
    component: Testing,
    exact: true,
    restricted: true,
    isPrivate: true,
    icon: TestingIcon,
    sidebar: true,
  },
  {
    name: 'login',
    path: ROUTES.LOGIN,
    component: Login,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    name: 'Sản phẩm',
    path: ROUTES.PRODUCT,
    component: Product,
    exact: true,
    restricted: false,
    isPrivate: true,
    icon: ProductIcon,
    sidebar: true,
  },
  {
    name: 'Hóa đơn',
    path: ROUTES.ORDER,
    component: Order,
    exact: true,
    restricted: false,
    isPrivate: true,
    icon: OrderIcon,
    sidebar: true,
  },
  {
    name: 'Chi tiết hóa đơn',
    path: ROUTES.ORDER_DETAIL,
    component: OrderDetail,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    name: 'Thống kê',
    path: ROUTES.STATISTIC,
    component: Statistic,
    exact: true,
    icon: StatisticIcon,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: 'Chi tiết khách hàng',
    path: ROUTES.CUSTOMER,
    component: CustomerDetail,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    name: 'Lịch tái khám',
    path: ROUTES.RETESTDATE,
    component: RetestDate,
    exact: true,
    icon: DateRangeIcon,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: 'Cài đặt',
    path: ROUTES.SETTING,
    component: Setting,
    exact: true,
    icon: SettingsIcon,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
];
