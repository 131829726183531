import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { TEST_QUEUE_IN_PAGE } from '@src/constants';
import { ConfirmDialog } from '../ConfirmDialog';

import { ListWrapper, StyledTestQueue } from './index.style';
import WaitItem from './WaitItem';

const INTERVAL_FETCH_QUEUE = 5 * 1000;

/**
 * @param {object} {
 *   queue: []Customer({ id, name, phoneNumber, age, address })
 * }
 */
const TestQueue = ({
  queue,
  activeCustomerId,
  inPage = TEST_QUEUE_IN_PAGE.RECEPTIONIST,
  setQueue,
  onPick,
}) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);

  const [deletingTestQueueId, setDeletingTestQueueId] = useState('');

  const handleClickWaitItem = (customer) => {
    if (onPick) onPick(customer);
  };

  const handleCloseDialog = () => {
    setDeletingTestQueueId('');
  };

  const fetchTestQueues = async () => {
    const res = await apis.testQueues.getTestQueues({});
    if (!res?.status) return;

    setQueue(res.result.testQueues);
  };

  const handleDeleteItem = (id) => {
    setDeletingTestQueueId(id);
  };

  const handleDeleteWaitItem = async () => {
    const res = await apis.testQueues.removeTestQueue(deletingTestQueueId);
    handleCloseDialog();
    if (!res?.status) {
      dispatch(actions.noti.push({ code: res?.code || 0, severity: 'error' }));
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Đã xóa khách khỏi hàng chờ',
        severity: 'success',
      }),
    );

    fetchTestQueues();
  };

  useEffect(() => {
    let interval;
    if (accessToken) {
      fetchTestQueues();
      interval = setInterval(() => {
        fetchTestQueues();
      }, INTERVAL_FETCH_QUEUE);
    }

    return () => {
      clearInterval(interval);
    };
  }, [accessToken]);

  return (
    <StyledTestQueue>
      <Typography className="title-queue">Danh sách chờ khám</Typography>
      <ListWrapper className={!queue.length && 'no-testQueues'}>
        {queue.length > 0 ? (
          queue.map((item, index) => (
            <WaitItem
              no={index}
              data={item}
              key={item.id}
              active={activeCustomerId === item.customer?.id}
              inPage={inPage}
              onSelect={handleClickWaitItem}
              onDelete={handleDeleteItem}
            />
          ))
        ) : (
          <b>Không có khách hàng trong hàng chờ</b>
        )}
      </ListWrapper>
      <ConfirmDialog
        open={!!deletingTestQueueId}
        title="Xóa khách hàng"
        description="Bạn có muốn xóa khách hàng này ra khỏi hàng chờ?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onClose={handleCloseDialog}
        onOk={handleDeleteWaitItem}
        onCancel={handleCloseDialog}
      />
    </StyledTestQueue>
  );
};

export { TestQueue };
