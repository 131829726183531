/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Box, Button, Grid, Link, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { ROUTES } from '@src/constants/route';
import { ProcessHandler } from '@src/components';
import { setCookie } from '@src/utils';
import { ACCESS_TOKEN_KEY } from '@src/constants';

const initialLogin = {
  username: '',
  password: '',
};

const validationSchema = yup.object({
  username: yup.string().required('Tên đăng nhập không được để trống'),
  password: yup.string().required('Mật khẩu không được để trống'),
});

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const res = await apis.auth.login(values.username, values.password);
    setLoading(false);
    if (!res?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: res?.code || 0,
          message: res?.message,
        }),
      );
      return;
    }

    const { expiresIn, accessToken } = res.result;
    setCookie(ACCESS_TOKEN_KEY, accessToken, expiresIn * 1000);
    dispatch(actions.auth.verifyToken(accessToken));

    history.push(ROUTES.RECEPTIONIST);
  };

  const formik = useFormik({
    initialValues: initialLogin,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box>
        <img width="200" src="/images/logo.png" alt="logo-login" />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box onSubmit={handleSubmitForm} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            placeholder="Nhập tên đăng nhập"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            placeholder="Nhập mật khẩu"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <ProcessHandler loading={loading} size={30} color="secondary">
              Đăng nhập
            </ProcessHandler>
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Quên mật khẩu?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
