import api from './api';

const login = async (username, password) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/auth/login',
      data: {
        username,
        password,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const verifyToken = async (accessToken) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/auth/verify',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const logout = async () => {
  try {
    const res = await api({
      method: 'POST',
      url: '/auth/logout',
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const changePassword = async ({ oldPassword, newPassword }) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/auth/change-password',
      data: {
        oldPassword,
        newPassword,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export { login, verifyToken, logout, changePassword };
