export const actionTypes = {
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
  UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
  LOGOUT: 'LOGOUT',
};

const verifyToken = (accessToken) => ({
  type: actionTypes.VERIFY_TOKEN,
  accessToken,
});

const verifyTokenSuccess = (accessToken, account) => ({
  type: actionTypes.VERIFY_TOKEN_SUCCESS,
  accessToken,
  account,
});

const updateUserInfo = (user) => ({
  type: actionTypes.UPDATE_ACCOUNT_INFO,
  user,
});

const logout = () => ({ type: actionTypes.LOGOUT });

const verifyTokenFailure = () => ({ type: actionTypes.VERIFY_TOKEN_FAILURE });

export {
  verifyToken,
  verifyTokenSuccess,
  verifyTokenFailure,
  updateUserInfo,
  logout,
};
