import React, { useState, useEffect } from 'react';
import {
  PageFrame,
  CustomTable,
  ConfirmDialog,
  ProcessHandler,
  StatusCard,
  productTypeCards,
} from '@src/components';
import {
  Divider,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Box,
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import apis from '@src/apis';
import { convertFormatDate, debounce, delimitNumber } from '@src/utils';
import actions from '@src/redux/actions';
import { PRODUCT_TYPE } from '@src/constants';
import { useDispatch } from 'react-redux';
import ListFilter from './ListFilter';
import { StyledProduct } from './index.style';
import CreateProductDialog from './CreateProductDialog';

const Product = () => {
  const dispatch = useDispatch();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });
  const [updatingProduct, setUpdatingProduct] = useState(null);
  const [typeProduct, setTypeProduct] = useState('');
  const [deletingProductId, setDeletingProductId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  const columns = [
    {
      field: 'name',
      align: 'left',
      id: 'name',
      title: 'Tên sản phẩm',
    },
    {
      field: 'type',
      align: 'center',
      id: 'type',
      title: 'Loại sản phẩm',
      render: (product) => (
        <Box fullWidth display="flex" justifyContent="center">
          <StatusCard {...productTypeCards[product.type]} />
        </Box>
      ),
    },
    {
      field: 'priceIn',
      align: 'center',
      id: 'priceIn',
      title: 'Giá vốn',
      render: (product) => delimitNumber(product.priceIn),
    },
    {
      field: 'priceOut',
      align: 'center',
      id: 'priceOut',
      title: 'Giá bán',
      render: (product) => delimitNumber(product.priceOut),
    },
    {
      field: 'quantity',
      align: 'center',
      id: 'quantity',
      title: 'Tồn kho',
    },
    {
      field: 'createAt',
      align: 'left',
      id: 'createAt',
      title: 'Ngày tạo',
      render: (order) => convertFormatDate(order.createdAt),
    },
    {
      id: 'actions',
      align: 'center',
      title: 'Hành động',
      render: (product) => (
        <>
          <Tooltip title="Xóa">
            <IconButton
              onClick={() => {
                setDeletingProductId(product.id);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <IconButton
              onClick={() => {
                setUpdatingProduct(product);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleCloseUpdateDialog = () => {
    setUpdatingProduct(null);
  };

  const handleUpdatedProduct = (updatedProduct) => {
    setUpdatingProduct();
    const tmProducts = products.map((item) => {
      if (item.id === updatedProduct.id) return updatedProduct;
      return item;
    });

    setProducts(tmProducts);
  };

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCreateNewProductDialog = (newProduct) => {
    setOpenCreateDialog(false);

    if (paging.page === 1) {
      setProducts((prevProducts) => [newProduct, ...prevProducts]);
    }
  };

  const handleChangeSelectType = (name, value) => {
    setTypeProduct(value === PRODUCT_TYPE.ALL ? null : value);
  };

  const handleCloseDeleteDialog = () => {
    setDeletingProductId('');
  };

  const handleDeleteProduct = async () => {
    const res = await apis.products.removeProduct(deletingProductId);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Xóa sản phẩm thành công',
        severity: 'success',
      }),
    );

    setProducts((prev) =>
      prev.filter((product) => product.id !== deletingProductId),
    );
    setDeletingProductId('');
  };

  const fetchProducts = async () => {
    setLoadingSearch(true);
    const res = await apis.products.getProducts({
      searchFields: 'name,description',
      search: searchValue,
      type: typeProduct || null,
      page: paging.page,
    });
    setLoadingSearch(false);
    if (!res?.status) return;

    setProducts(res.result.products);
    setPaging({ ...paging, total: res.result.total });
  };

  const inputSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    debounce(fetchProducts, 500)(searchValue);
  }, [typeProduct, paging.page, searchValue]);

  return (
    <PageFrame title="Quản lý sản phẩm">
      <StyledProduct>
        <Divider />
        <div className="filter">
          <div className="options">
            <ListFilter onChangeSelect={handleChangeSelectType} />
            <div className="search">
              <TextField
                className="input-search"
                size="small"
                placeholder="Tìm kiếm sản phẩm"
                onChange={inputSearchChange}
                InputProps={{
                  endAdornment: loadingSearch ? (
                    <ProcessHandler loading size={20} />
                  ) : null,
                }}
              />
            </div>
          </div>
          <div className="create-product">
            <Button
              variant="outlined"
              disableElevation
              endIcon={<Add />}
              size="small"
              onClick={handleOpenDialog}
            >
              Thêm sản phẩm
            </Button>
            <CreateProductDialog
              open={openCreateDialog}
              onClose={handleCloseDialog}
              onCreateProduct={handleCreateNewProductDialog}
            />
            <CreateProductDialog
              open={Boolean(updatingProduct)}
              product={updatingProduct}
              onClose={handleCloseUpdateDialog}
              onUpdateProduct={handleUpdatedProduct}
            />
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={products}
          total={paging.total}
          page={paging.page}
          onChangePage={handleChangePage}
        />
      </StyledProduct>
      <ConfirmDialog
        open={Boolean(deletingProductId)}
        title="Xóa sản phẩm"
        description="Bạn có muốn xóa sản phẩm này?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onCancel={handleCloseDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onOk={handleDeleteProduct}
      />
    </PageFrame>
  );
};

export default Product;
