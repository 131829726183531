import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { StyledServiceStep } from '@src/containers/Testing/ServiceStep/index.style';
import SearchProduct from '@src/containers/Testing/ServiceStep/SearchProduct';
import { BaseDialog, ProcessHandler } from '@src/components';
import ProductOrder from '@src/containers/Testing/ServiceStep/ProductOrder';
import { StyledButtonGroup } from '@src/containers/Testing/index.style';
import { useDispatch } from 'react-redux';
import { ORDER_STATUS } from '@src/constants';
import apis from '@src/apis';
import { ERROR_CODE } from '@src/errors/code';
import actions from '@src/redux/actions';
import SelectOption from '@src/components/SelectOption/SelectOption';

const orderStatus = {
  key: 'select-order-status',
  title: 'Thay đổi trạng thái',
  type: 'select-options',
  name: 'order status',
  options: [
    { value: ORDER_STATUS.PAID, label: 'Đã thanh toán' },
    { value: ORDER_STATUS.PENDING, label: 'Đang xử lý' },
    { value: ORDER_STATUS.CANCELLED, label: 'Đã huỷ' },
  ],
};

const UpdateOrderDialog = ({ open, order, onClose, onUpdateOrderSuccess }) => {
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [discountAll, setDiscountAll] = useState('');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChangeOrderStatus = (name, value) => {
    setStatus(value);
  };

  const handleSelectProduct = (prod) => {
    let isExist = false;
    let tmpProducts = orderedProducts.map((item) => {
      if (item.name === prod.name && item.id === prod.id) {
        isExist = true;
        return {
          ...item,
          quantity: `${Number(item.quantity) + 1}`,
        };
      }
      return item;
    });

    if (!isExist)
      tmpProducts = [
        ...tmpProducts,
        {
          id: prod.id,
          name: prod.name,
          quantity: 1,
          discount: prod.discount || '0',
          type: prod.type,
          priceOut: prod.priceOut,
          remainQuantity: prod.quantity,
        },
      ];

    // TODO: group product by type

    setOrderedProducts(tmpProducts);
  };

  const handleRemoveProduct = (productId) => {
    const tmpProducts = orderedProducts.filter((prod) => prod.id !== productId);

    setOrderedProducts(tmpProducts);
  };

  const handleChangeProduct = (prod, { name, value }) => {
    const tmpProducts = orderedProducts.map((item) => {
      if (item.id === prod.id)
        return {
          ...item,
          [name]: value,
        };

      return item;
    });

    setOrderedProducts(tmpProducts);
  };

  const handleErrorUpdateOrder = (code = 0, message = '') => {
    if (code === ERROR_CODE.RUN_OUT_PRODUCT) {
      const prod = orderedProducts.find((item) => item.id === message);
      if (prod) {
        dispatch(
          actions.noti.push({
            message: `Sản phẩm ${prod.name} đã hết hàng!`,
            severity: 'error',
          }),
        );
        return;
      }
    }
    dispatch(actions.noti.push({ code: code || 0, severity: 'error' }));
  };

  const updateOrder = async () => {
    const products = orderedProducts.map((item) => ({
      productId: item.id,
      discount: Number(item.discount),
      quantity: Number(item.quantity),
    }));

    setLoading(true);
    const res = await apis.orders.forceUpdateOrder({
      orderId: order.id,
      products,
      note,
      discount: Number(discountAll),
      status,
    });
    setLoading(false);

    if (!res?.status) {
      handleErrorUpdateOrder(res?.code, res?.message);
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Cập nhật đơn thành công',
        severity: 'success',
      }),
    );

    onUpdateOrderSuccess(res.result);
  };

  useEffect(() => {
    if (!open || !order) return;

    const oProducts = order.products.map((prod) => ({
      id: prod.product.id,
      name: prod.product.name,
      quantity: prod.quantity,
      discount: prod.discount || '0',
      type: prod.product.type,
      priceOut: prod.priceOut, // We still get the old price here
      remainQuantity: prod.product.quantity,
    }));

    setOrderedProducts(oProducts);
    setNote(order.note || '');
    setDiscountAll(order.discount || '');
    setStatus(order.status);
  }, [open, order]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Cập nhật đơn hàng"
      maxWidth="100%"
    >
      <Box padding="0 12px 16px 12px">
        <StyledServiceStep>
          <Typography className="title-service">
            Chọn Kính thuốc và Dịch vụ
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} lg={4} className="left-col">
              <SearchProduct
                onPick={handleSelectProduct}
                note={note}
                setNote={setNote}
              />
            </Grid>
            <Grid item md={12} lg={8} className="right-col">
              <ProductOrder
                products={orderedProducts}
                onRemove={handleRemoveProduct}
                onChange={handleChangeProduct}
                discountAll={discountAll}
                setDiscountAll={setDiscountAll}
              />
              <SelectOption
                key={orderStatus.key}
                className="select-option"
                title={orderStatus.title}
                name={orderStatus.name}
                value={status}
                options={orderStatus.options}
                onChange={handleChangeOrderStatus}
              />
              <StyledButtonGroup>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={onClose}
                >
                  Huỷ
                </Button>
                <Button size="small" variant="contained" onClick={updateOrder}>
                  <ProcessHandler loading={loading} size={25}>
                    Lưu
                  </ProcessHandler>
                </Button>
              </StyledButtonGroup>
            </Grid>
          </Grid>
        </StyledServiceStep>
      </Box>
    </BaseDialog>
  );
};

export default UpdateOrderDialog;
