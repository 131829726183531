import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/lab';

import { TextField, Typography } from '@mui/material';
import { RETEST_DATE_OPTIONS, RETEST_DATE_TYPE } from '@src/constants';
import { StyledReTestDate } from './index.style';
import SelectOption from '../SelectOption/SelectOption';

const retestDateOptions = {
  key: 'select-options',
  title: 'Khám lại sau',
  type: 'select-options',
  defaultValue: 0,
  name: 'reTestDate',
  options: RETEST_DATE_OPTIONS,
};

const ReTestDatePicker = ({
  title,
  type,
  dateValue,
  optionValue,
  onDateChange,
  onTypeChange,
}) => {
  const [reTestDate, setReTestDate] = useState();

  const handleChooseReTestDate = (name, day) => {
    const today = new Date();
    const futureDate = new Date(today.getTime() + day * 24 * 60 * 60 * 1000);
    onDateChange(futureDate, day);
  };

  const changeOptionReTestDate = () => {
    if (type === RETEST_DATE_TYPE.SELECT) {
      onTypeChange(RETEST_DATE_TYPE.DATEPICKER);
      return;
    }

    if (optionValue) {
      handleChooseReTestDate(null, optionValue);
    }
    onTypeChange(RETEST_DATE_TYPE.SELECT);
  };

  const handleDateChange = (date) => {
    if (!Number.isNaN(date.getTime())) {
      onDateChange(date);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setReTestDate(dateValue);
    }
  }, [dateValue]);

  const renderReTestDatePicker = () => {
    if (type === RETEST_DATE_TYPE.SELECT)
      return (
        <SelectOption
          key={retestDateOptions.key}
          className="input-date"
          title={retestDateOptions.title}
          name={retestDateOptions.name}
          value={optionValue}
          options={retestDateOptions.options}
          onChange={handleChooseReTestDate}
        />
      );

    return (
      <DatePicker
        name="startDate"
        inputFormat="dd/MM/yyyy"
        mask="__-__-____"
        value={reTestDate}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField className="input-date" size="small" {...params} />
        )}
      />
    );
  };

  return (
    <StyledReTestDate>
      <div className="reTestDate">
        <Typography className="prescription-title">
          <b>
            <u>{title}</u>
          </b>
        </Typography>
        <div className="reTestDate-option">{renderReTestDatePicker()}</div>
        <Typography className="option-advance" onClick={changeOptionReTestDate}>
          {type === RETEST_DATE_TYPE.SELECT ? 'Tuỳ chỉnh' : 'Quay lại'}
        </Typography>
      </div>
    </StyledReTestDate>
  );
};

export default ReTestDatePicker;
