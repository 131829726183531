export const initialIndicators = {
  noMpVa: '',
  noMpPh: '',
  noMtVa: '',
  noMtPh: '',
  noPD: '',
  noCT: '',
  noEOM: '',
  noNPC: '',
  oldMP: '',
  oldMT: '',
  oldADD: '',
  oldVA1: '',
  oldVA2: '',
  newMP: '',
  newMT: '',
  newADD: '',
  newVA1: '',
  newVA2: '',
};

export const parseIndicators = (stringIndicators) => {
  try {
    return JSON.parse(stringIndicators);
  } catch (_) {
    return initialIndicators;
  }
};
