import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import { Card } from '../Card';
import SelectColorSet from './SelectColorSet';
import { ProcessHandler } from '../common';

import { StyledConfigTheme } from './index.style';
import { ConfirmDialog } from '../ConfirmDialog';

const ConfigTheme = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [colorSetActive, setColorSetActive] = useState(
    theme.palette.primary.main,
  );
  const [openDialog, setOpenDialog] = useState(false);

  const firstColorSetConfig = useRef(theme.palette.primary.main);

  const handleGetColorSetActive = (colorSet) => {
    setColorSetActive(colorSet?.primary);

    dispatch(actions.theme.changeColor(colorSet));
  };

  const handleCloseChangeColorSet = () => {
    setOpenDialog(false);
  };

  const handleSaveColorSet = () => {
    if (colorSetActive === firstColorSetConfig.current) return;

    setOpenDialog(true);
  };

  const handleSaveNewTheme = () => {
    // TODO: fetch API update theme
    console.log('success');
  };

  // Restore the original color when not confirming the color change.
  useEffect(
    () => () => {
      // const colorSet = COLOR_SETS.find(
      //   (colorSetItem) => colorSetItem.primary === firstColorSetConfig.current,
      // );
      // dispatch(actions.theme.changeColor(colorSet));
    },
    [],
  );

  return (
    <Card title="Hiển thị">
      <StyledConfigTheme>
        <SelectColorSet
          colorSetActive={colorSetActive}
          onSelect={handleGetColorSetActive}
        />
        <div className="button">
          <Button variant="contained" onClick={handleSaveColorSet}>
            <ProcessHandler loading={false}>Lưu thay đổi</ProcessHandler>
          </Button>
        </div>
      </StyledConfigTheme>
      <ConfirmDialog
        open={openDialog}
        title="Chọn màu chủ đạo"
        description="Thay đổi màu của ứng dụng?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onCancel={handleCloseChangeColorSet}
        onClose={handleCloseChangeColorSet}
        onOk={handleSaveNewTheme}
      />
    </Card>
  );
};

export default ConfigTheme;
