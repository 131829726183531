const { styled } = require('@mui/material');

export const StyledUpdateFacility = styled('div')`
  .logo-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .image {
      width: 270px;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
  }
`;
