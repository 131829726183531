import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from '@src/styles/theme';
import { useSelector } from 'react-redux';

const ThemeWrapper = ({ children }) => {
  const colorSetPreview = useSelector((state) => state.theme);

  return (
    <ThemeProvider theme={getTheme(colorSetPreview)}>{children}</ThemeProvider>
  );
};

export default ThemeWrapper;
