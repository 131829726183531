/* eslint-disable no-control-regex */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { BaseDialog, ProcessHandler } from '@src/components';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { GENDER } from '@src/constants';
import { omitIsNil } from '@src/utils';

import { StyledCreateCustomer } from './index.style';

const initialCustomer = {
  name: '',
  phoneNumber: '',
  address: '',
  birthday: '',
  gender: 'MALE',
  anamnesis: '',
  isFamily: false,
};

const phoneNumberRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

const validationSchema = yup.object({
  name: yup.string('Nhập tên khách hàng').required('Tên không được để trống'),
  phoneNumber: yup
    .string('Nhập số điện thoại')
    .matches(phoneNumberRegex, 'Số điện thoại sai định dạng'),
  birthday: yup.date().optional(),
});

const CreateCustomerDialog = ({
  open,
  customer,
  fromSearch = '',
  onClose,
  onCreateCustomer,
  onUpdateCustomer,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (values, helpers) => {
    let execFunc = apis.customers.createCustomer;
    const payload = omitIsNil(values);

    if (customer) {
      execFunc = apis.customers.updateCustomer;
      payload.customerId = customer.id;
    }

    setLoading(true);
    const res = await execFunc(payload);
    setLoading(false);

    if (!res || !res.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: res?.code || 0,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'success',
        message: customer
          ? 'Cập nhật khách hàng thành công'
          : 'Tạo khách hàng thành công',
      }),
    );
    helpers.resetForm(initialCustomer);
    if (customer) {
      onUpdateCustomer(res.result);
      return;
    }
    onCreateCustomer(res.result.id);
  };

  const formik = useFormik({
    initialValues: initialCustomer,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleChangeIsFamily = (event) => {
    formik.setValues({ ...formik.values, isFamily: event.target.checked });
  };

  useEffect(() => {
    if (!fromSearch) return;
    // check if is not phoneNumber => assign phone otherwise assign to name
    if (!/\d/.test(fromSearch)) {
      formik.setFieldValue('name', fromSearch);
    } else formik.setFieldValue('phoneNumber', fromSearch);
  }, [fromSearch, open]);

  useEffect(() => {
    if (!open || !customer) return;
    let birthday = '';
    if (customer.birthday)
      birthday = dayjs(customer.birthday).format('YYYY-MM-DD');

    formik.setValues({
      name: customer.name || '',
      phoneNumber: customer.phoneNumber || '',
      address: customer.address || '',
      gender: customer.gender || 'MALE',
      anamnesis: customer.anamnesis || '',
      isFamily: customer.isFamily || false,
      birthday,
    });
  }, [customer, open]);

  return (
    <BaseDialog
      title={customer ? 'Cập nhật thông tin khách hàng' : 'Tạo mới khách hàng'}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledCreateCustomer>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div className="control-item">
                <Typography>
                  Tên khách hàng <span className="req">*</span>
                </Typography>
                <TextField
                  size="small"
                  placeholder="Nhập tên khách hàng"
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>

              <div className="control-item">
                <Typography>Số điện thoại</Typography>
                <TextField
                  size="small"
                  name="phoneNumber"
                  placeholder="Nhập số điện thoại"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.phoneNumber)}
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </div>
              <div className="control-item">
                <Typography>Địa chỉ</Typography>
                <TextField
                  size="small"
                  name="address"
                  placeholder="Nhập địa chỉ"
                  id="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="control-item">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeIsFamily}
                      checked={formik.values.isFamily}
                      name="isFamily"
                      id="isFamily"
                    />
                  }
                  label="Đi gia đình"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="control-item">
                <Typography>Ngày sinh</Typography>
                <TextField
                  size="small"
                  name="birthday"
                  id="birthday"
                  type="date"
                  placeholder="Nhập tuổi"
                  value={formik.values.birthday}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.birthday)}
                  helperText={formik.touched.birthday && formik.errors.birthday}
                />
              </div>
              <div className="control-item">
                <Typography>Giới tính</Typography>
                <Select
                  id="gender"
                  value={formik.values.gender}
                  size="small"
                  name="gender"
                  placeholder="Chọn giới tính"
                  onChange={formik.handleChange}
                >
                  {Object.keys(GENDER).map((item) => (
                    <MenuItem key={item} value={item}>
                      {GENDER[item]}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="control-item">
                <Typography>Tiền sử bệnh</Typography>
                <TextField
                  size="small"
                  name="anamnesis"
                  placeholder="Nhập tiền sử bệnh"
                  id="anamnesis"
                  value={formik.values.anamnesis}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <div className="button-group">
            <Button variant="contained" type="submit">
              <ProcessHandler loading={loading}>
                {customer ? 'Cập nhật' : 'Tạo'}
              </ProcessHandler>
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Hủy
            </Button>
          </div>
        </StyledCreateCustomer>
      </form>
    </BaseDialog>
  );
};

export default CreateCustomerDialog;
