const { styled } = require('@mui/material');

export const StyledInputControl = styled('div')`
  margin: 12px 0;
  width: 100%;

  p:first-of-type {
    margin-bottom: 3px;
  }

  .req {
    color: red;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    width: 100%;
  }
`;
