import React from 'react';
import { Typography } from '@mui/material';
import { Close, LocalHospital } from '@mui/icons-material';
import { TEST_QUEUE_IN_PAGE } from '@src/constants';

const { StyledWaitItem } = require('./index.style');

const WaitItem = ({ no, data, active, inPage, onSelect, onDelete }) => {
  const { customer = {}, isTesting } = data;

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(data.id);
  };

  const renderAction = () => {
    if (inPage === TEST_QUEUE_IN_PAGE.RECEPTIONIST && !isTesting) {
      return <Close onClick={handleDelete} />;
    }

    if (active || isTesting)
      return <LocalHospital className={active ? 'active-icon' : ''} />;

    return '';
  };

  return (
    <StyledWaitItem
      onClick={() => onSelect(customer)}
      active={active}
      padLast={inPage === TEST_QUEUE_IN_PAGE.TESTING}
    >
      <span className="no">{+no + 1}.</span>
      <div className="item-title">
        <Typography>{customer?.name}</Typography>
      </div>
      {renderAction()}
    </StyledWaitItem>
  );
};

export default WaitItem;
