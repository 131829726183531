import { COLOR } from '@src/styles/color';

const { styled } = require('@mui/material');

export const StyledDiagnoseStep = styled('div')`
  padding-top: 10px;

  .col-title {
    font-weight: 600;
    font-size: 18px;
  }

  .diagnose {
    display: flex;
    gap: 6px;
    margin-top: -16px;
  }

  .reTestDate-Picker {
    margin-top: 12px;
  }
`;

export const StyledSearchMedicine = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;

  .title-med {
    font-weight: 600;
  }

  .medicine-list {
    border-top: 1px solid ${COLOR.divider};
    border-radius: 5px;

    .medicine-item {
      border-bottom: 1px solid ${COLOR.divider};
    }
  }

  .other-medicine {
    display: flex;
    gap: 5px;
    align-items: center;

    .other-medicine-input {
      flex-grow: 1;
    }
  }
`;

export const StyledPrescription = styled('div')`
  display; flex;
  height: 395px;
  padding: 12px 0;
  overflow: auto;

  .quantity-input {
    width: 42px;
  }

  .ins-input {
    width: 100%;
  }
`;
