import React from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';

/**
 *
 * @param {*} label:String required, label of select,
 * @param {*} name:String required, label of select,
 * @param {*} defaultValue: String required, defaultValue of select
 * @param {*} className: String , classname of select,
 * @param {*} error: String , error of select,
 * @param {*} helperText: String , helperText of select,
 * @param {*} options: [{label: string, value: any}] required, value of select
 * @param {*} onChange: callback function: required, update state
 * @returns
 */

const SelectOption = ({
  title,
  options,
  defaultValue,
  className,
  name,
  errorSelect,
  helperTextSelect,
  value,
  onChange,
}) => {
  const handleChangeOption = (event) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <FormControl
      size="small"
      sx={{ m: 1, minWidth: 80, margin: 0 }}
      error={errorSelect}
      helperText={helperTextSelect}
    >
      {title && <Typography>{title}</Typography>}
      <Select
        labelId={name}
        id={name}
        name={name}
        className={className}
        defaultValue={
          defaultValue !== undefined ? defaultValue : options[0].value
        }
        autoWidth
        value={value}
        onChange={handleChangeOption}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectOption;
