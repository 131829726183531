import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import apis from '@src/apis';
import BarChart from '@src/components/Chart/BarChart';
import DoughnutChart from '@src/components/Chart/DoughnutChart';
import moment from 'moment';

import CustomDatePickerRangeNew from '@src/components/CustomDatePickerRangeNew';
import { PageFrame, ProcessHandler, productTypeCards } from '@src/components';
import IconTotalPaid from '@src/assets/icons/total-revenue.png';
import IconTotalCancel from '@src/assets/icons/icon-total-revenue.png';
import IconTotalPending from '@src/assets/icons/total-used-customer.png';
import StatsCard from '@src/components/StatsCard';
import { ORDER_STATUS } from '@src/constants';

import { StyledStatistic, StyledStatsRevenueCards } from './index.style';

const STATS_DATE_BY = {
  day: 'Ngày',
  month: 'Tháng',
  year: 'Năm',
};

const initialCreatedAt = () => {
  const endDate = new Date();
  const startDate = moment().startOf('month').toDate();
  return [startDate, endDate];
};

const optionsDatePicker = [
  { label: 'Hôm qua', value: -1 },
  { label: 'Tuần này', value: 'thisWeek' },
  { label: 'Tháng này', value: 'thisMonth' },
  { label: 'Quý này', value: 'thisQuarter' },
  { label: 'Năm nay', value: 'thisYear' },
  { label: 'Tuần trước', value: 'lastWeek' },
  { label: 'Tháng trước', value: 'lastMonth' },
  { label: 'Quý trước', value: 'lastQuarter' },
  { label: 'Năm trước', value: 'lastYear' },
];

const Statistic = () => {
  const [dateType, setDateType] = useState('day');
  const [dataRevenueByDate, setDataRevenueByDate] = useState();
  const [dataBenefitByDate, setDataBenefitByDate] = useState();
  const [revenueByProduct, setRevenueByProduct] = useState({});
  const [benefitByProduct, setBenefitByProduct] = useState({});
  const [statsByOrder, setStatsByOrder] = useState({});

  const [queryDate, setQueryDate] = useState(initialCreatedAt);

  const [loading, setLoading] = useState({
    byOrder: false,
    byDate: false,
  });

  const offLoading = (key) => setLoading({ ...loading, [key]: false });
  const onLoading = (key) => setLoading({ ...loading, [key]: true });

  const fetchStatisticByDate = async () => {
    onLoading('byDate');
    const res = await apis.statistics.statsRevenueByDate({
      dateType,
      startDate: queryDate[0],
      endDate: queryDate[1],
    });
    offLoading('byDate');
    if (!res?.status) return;

    const revenueData = {};
    const benefitData = {};

    res.result.forEach((item) => {
      revenueData[item[dateType]] = { 'Doanh thu': item.totalRevenue };
      benefitData[item[dateType]] = { 'Lợi nhuận': item.totalBenefit };
    });

    setDataRevenueByDate(revenueData);
    setDataBenefitByDate(benefitData);
  };

  const fetchStatistic = async () => {
    onLoading('byOrder');
    const [resProductType, resOrderStatus] = await Promise.all([
      apis.statistics.statsRevenueByProductType({
        startDate: queryDate[0],
        endDate: queryDate[1],
      }),
      apis.statistics.statsRevenueByOrderStatus({
        startDate: queryDate[0],
        endDate: queryDate[1],
      }),
    ]);
    offLoading('byOrder');

    if (!resProductType?.status || !resOrderStatus?.status) return;

    // Map data  of product type
    const revenueData = {};
    const benefitData = {};
    resProductType.result.forEach((item) => {
      revenueData[productTypeCards[item.type].name] = item.revenue;
      benefitData[productTypeCards[item.type].name] = item.benefit;
    });

    setRevenueByProduct(revenueData);
    setBenefitByProduct(benefitData);

    // Map data of order status
    const tmpStatsOrder = {};
    resOrderStatus.result.forEach((item) => {
      tmpStatsOrder[item.status] = { ...item };
    });
    setStatsByOrder(tmpStatsOrder);
  };

  const handleChangeDatePickerRange = (value = []) => {
    setQueryDate(value);
  };

  const handleResetFilterDate = () => {
    setQueryDate(initialCreatedAt());
  };

  const handleSelectDateBackOption = (dayType) => {
    let startDate;
    let endDate = moment();

    switch (dayType) {
      case 'thisWeek':
        startDate = moment().startOf('week').add(1, 'd');
        break;
      case 'thisMonth':
        startDate = moment().startOf('month');
        break;
      case 'thisQuarter':
        startDate = moment().startOf('quarter');
        break;
      case 'thisYear':
        startDate = moment().startOf('year');
        break;
      case 'lastWeek':
        startDate = moment().subtract('week').startOf('week');
        endDate = moment().subtract('week').endOf('week');
        break;
      case 'lastMonth':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
        break;
      case 'lastQuarter':
        startDate = moment().subtract(1, 'quarter').startOf('quarter');
        endDate = moment().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'lastYear':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');
        break;
      default:
        startDate = moment().subtract(1, 'd').startOf('day').toString();
    }
    console.log(`[startDate.toString(), endDate.toString()]: ${dayType}`, [
      startDate.toString(),
      endDate.toString(),
    ]);
    return [startDate.toString(), endDate.toString()];
  };

  useEffect(() => {
    fetchStatisticByDate();
  }, [dateType, queryDate]);

  useEffect(() => {
    fetchStatistic();
  }, [queryDate]);

  return (
    <PageFrame title="Thống kê doanh thu">
      <StyledStatistic>
        <div className="filter-wrapper">
          <CustomDatePickerRangeNew
            value={queryDate}
            isRefresh
            optionsDatePicker={optionsDatePicker}
            onChange={handleChangeDatePickerRange}
            onSelectDateBackOption={handleSelectDateBackOption}
            handleRefresh={handleResetFilterDate}
          />
        </div>
        <StyledStatsRevenueCards>
          <StatsCard
            title="Đã thanh toán"
            data={statsByOrder[ORDER_STATUS.PAID]}
            icon={IconTotalPaid}
          />
          <StatsCard
            title="Đã hủy"
            data={statsByOrder[ORDER_STATUS.CANCELLED]}
            icon={IconTotalCancel}
          />
          <StatsCard
            title="Đang chờ"
            data={statsByOrder[ORDER_STATUS.PENDING]}
            icon={IconTotalPending}
          />
        </StyledStatsRevenueCards>
        <Grid sx={{ my: '40px' }} container spacing={3}>
          <Grid item sm={12} md={6}>
            <ProcessHandler mt="20px" align="center" loading={loading.byOrder}>
              <DoughnutChart
                data={revenueByProduct}
                title="Thống kê doanh thu theo loại sản phẩm"
              />
            </ProcessHandler>
          </Grid>
          <Grid item sm={12} md={6}>
            <ProcessHandler mt="20px" align="center" loading={loading.byOrder}>
              <DoughnutChart
                data={benefitByProduct}
                title="Thống kê lợi nhuận theo loại sản phẩm"
              />
            </ProcessHandler>
          </Grid>
        </Grid>
        <div>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {Object.keys(STATS_DATE_BY).map((key) => (
              <Button
                key={key}
                variant={dateType === key ? 'contained' : 'outlined'}
                onClick={() => setDateType(key)}
              >
                {STATS_DATE_BY[key]}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <ProcessHandler mt="50px" align="center" loading={loading.byDate}>
          <BarChart data={dataRevenueByDate} title="Thống kê theo doanh thu" />
          <Box py="30px" />
          <BarChart data={dataBenefitByDate} title="Thống kê theo lợi nhuận" />
        </ProcessHandler>
      </StyledStatistic>
    </PageFrame>
  );
};

export default Statistic;
