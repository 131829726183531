import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { BaseDialog, ProcessHandler } from '@src/components';
import SelectOption from '@src/components/SelectOption/SelectOption';
import { Grid, Typography, TextField, Button } from '@mui/material';
import { PRODUCT_TYPE } from '@src/constants';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { StyledCreateProduct } from './index.style';

const productType = {
  key: 'type-product',
  name: 'type',
  defaultValue: '',
  options: [
    { value: PRODUCT_TYPE.MEDICINE, label: 'Thuốc' },
    { value: PRODUCT_TYPE.SERVICE, label: 'Dịch vụ' },
    { value: PRODUCT_TYPE.GLASSES, label: 'Kính mắt' },
    { value: PRODUCT_TYPE.OTHER, label: 'Khác' },
  ],
};

const initialProduct = {
  name: '',
  description: '',
  priceIn: 0,
  priceOut: 0,
  discount: 0,
  quantity: 0,
  type: '',
};

const validationSchema = yup.object({
  name: yup
    .string('Nhập tên sản phẩm')
    .required('Tên sản phẩm không được để trống'),
  priceIn: yup
    .number()
    .min(0, 'Giá sản phẩm phải lớn hơn hoặc bằng 0')
    .required('Tên sản phẩm không được để trống')
    .typeError('Giá tiền phải bằng số'),
  priceOut: yup
    .number()
    .min(0, 'Giá sản phẩm phải lớn hơn hoặc bằng 0')
    .required('Tên sản phẩm không được để trống')
    .typeError('Giá tiền phải bằng số'),
  discount: yup
    .number()
    .min(0, 'Giá trị phải lớn hơn hoặc bằng 0')
    .max(100, 'Giá trị phải nhỏ hơn hoặc bằng 100')
    .typeError('Dữ liệu không được phép là một ký tự'),
  quantity: yup
    .number()
    .min(0, 'Giá sản phẩm phải lớn hơn hoặc bằng 0')
    .typeError('Dữ liệu không được phép là một ký tự'),
  type: yup
    .string()
    .oneOf(Object.values(PRODUCT_TYPE))
    .required('Loại sản phẩm không được để trống'),
});

const CreateProductDialog = ({
  open,
  product,
  onClose,
  onCreateProduct,
  onUpdateProduct,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (values, helpers) => {
    console.log('submit form');
    let execFunc = apis.products.createProduct;
    const payload = values;

    if (product) {
      execFunc = apis.products.updateProduct;
      payload.productId = product.id;
    }

    setLoading(true);
    const res = await execFunc(payload);
    setLoading(false);

    if (!res || !res.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: res?.code || 0,
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'success',
        message: product
          ? 'Cập nhật sản phẩm thành công'
          : 'Tạo sản phẩm thành công',
      }),
    );
    helpers.resetForm(initialProduct);
    initialProduct.type = '';

    if (product) {
      onUpdateProduct(res.result);
      return;
    }

    onCreateProduct(res.result);
  };

  const formik = useFormik({
    initialValues: initialProduct,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  const handleChangeSelect = (name, value) => {
    formik.setValues({ ...formik.values, [name]: value });
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    if (!open || !product) return;
    formik.setValues({
      name: product.name || '',
      description: product.description || '',
      priceIn: product.priceIn || 0,
      priceOut: product.priceOut || 0,
      discount: product.discount || 0,
      quantity: product.quantity || 0,
      type: product.type,
    });
    productType.defaultValue = product.type;
  }, [product, open]);

  return (
    <BaseDialog
      title={product ? 'Cập nhật sản phẩm' : 'Tạo mới sản phẩm'}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledCreateProduct>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div className="control-item">
                <Typography>
                  Tên sản phẩm <span className="req">*</span>
                </Typography>
                <TextField
                  size="small"
                  placeholder="Nhập tên sản phẩm"
                  name="name"
                  id="name"
                  onKeyDown={handleKeyDown}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div className="control-item">
                <Typography>Giá bán ra</Typography>
                <TextField
                  size="small"
                  name="priceOut"
                  placeholder="Nhập giá bán ra"
                  id="priceOut"
                  type="number"
                  value={formik.values.priceOut}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.priceOut)}
                  helperText={formik.touched.priceOut && formik.errors.priceOut}
                />
              </div>
              <div className="control-item">
                <Typography>Giá nhập vào</Typography>
                <TextField
                  size="small"
                  name="priceIn"
                  placeholder="Nhập giá nhập vào"
                  id="priceIn"
                  type="number"
                  value={formik.values.priceIn}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.priceIn)}
                  helperText={formik.touched.priceIn && formik.errors.priceIn}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="control-item">
                <Typography>Chiết khấu</Typography>
                <TextField
                  size="small"
                  name="discount"
                  placeholder="Nhập chiết khấu"
                  id="discount"
                  type="number"
                  value={formik.values.discount}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.discount)}
                  helperText={formik.touched.discount && formik.errors.discount}
                />
              </div>
              <div className="control-item">
                <Typography>Số Lượng</Typography>
                <TextField
                  size="small"
                  name="quantity"
                  placeholder="Nhập số Lượng"
                  id="quantity"
                  type="number"
                  value={formik.values.quantity}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                />
              </div>
              <div className="control-item">
                <Typography>
                  Loại sản phẩm <span className="req">*</span>
                </Typography>
                <SelectOption
                  className="type-product"
                  key={productType.key}
                  defaultValue={productType.defaultValue}
                  name={productType.name}
                  options={productType.options}
                  onChange={handleChangeSelect}
                  errorSelect={Boolean(formik.errors.type)}
                  helperTextSelect={formik.touched.type && formik.errors.type}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="description">
              <div className="control-item">
                <Typography>Mô tả</Typography>
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  name="description"
                  placeholder="Nhập mô tả"
                  id="description"
                  onKeyDown={handleKeyDown}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <div className="button-group">
            <Button variant="contained" type="submit">
              <ProcessHandler loading={loading}>
                {product ? 'Cập nhật' : 'Tạo'}
              </ProcessHandler>
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Hủy
            </Button>
          </div>
        </StyledCreateProduct>
      </form>
    </BaseDialog>
  );
};

export default CreateProductDialog;
