const ROUTES = {
  LOGIN: '/login',
  TESTING: '/kham-benh',
  RECEPTIONIST: '/le-tan',
  PRODUCT: '/san-pham',
  ORDER: '/hoa-don',
  ORDER_DETAIL: '/hoa-don/:orderId',
  STATISTIC: '/thong-ke',
  CUSTOMER: '/khach-hang/:customerId',
  SETTING: '/cai-dat',
  RETESTDATE: '/lich-tai-kham',
};

export { ROUTES };
