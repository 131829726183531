import { Button, TextField, Typography } from '@mui/material';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProcessHandler } from '@src/components';

import { StyledTestStep } from './index.style';
import { StyledButtonGroup } from '../index.style';
import Indicators from './Indicators';
import { initialIndicators, parseIndicators } from '../indicators';

const TestStep = forwardRef(
  (
    { customerId, testing, onCreateTesting, onUpdateTesting, setHasChanged },
    ref,
  ) => {
    const [reason, setReason] = useState('');
    const [indicators, setIndicators] = useState(initialIndicators);

    const handleCreateTesting = () => {
      if (!customerId) return;
      const execFunc = onUpdateTesting || onCreateTesting;
      execFunc({ customerId, reason, indicators });
    };

    const handleChangeReason = (event) => {
      setReason(event.target.value);
      setHasChanged(true);
    };

    const handleChangeIndicators = (name, value) => {
      setHasChanged(true);
      setIndicators({ ...indicators, [name]: value });
    };

    useImperativeHandle(ref, () => ({
      updateTesting: () => {
        onUpdateTesting({ reason, indicators, draft: true });
      },
      createTesting: () => {
        onCreateTesting({ customerId, reason, indicators, draft: true });
      },
    }));

    useEffect(() => {
      if (testing) {
        setReason(testing?.reason || '');
        setIndicators(parseIndicators(testing?.indicators));
      }
    }, [testing]);

    return (
      <StyledTestStep>
        <div className="reason-testing">
          <Typography>Lý do khám</Typography>
          <TextField
            className="input"
            value={reason}
            name="reason"
            onChange={handleChangeReason}
            placeholder="Lý do khám bệnh"
          />
        </div>
        <Indicators indicators={indicators} onChange={handleChangeIndicators} />
        <StyledButtonGroup>
          <Button
            size="small"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleCreateTesting}
          >
            <ProcessHandler>Tiếp theo</ProcessHandler>
          </Button>
        </StyledButtonGroup>
      </StyledTestStep>
    );
  },
);

export default TestStep;
