import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { parseIndicators } from '@src/containers/Testing/indicators';
import { RETEST_DATE_OPTIONS, RETEST_DATE_TYPE } from '@src/constants';
import { StyledTestResult } from '../index.style';
import NoGlass from './NoGlass';
import SingleGlass from './SingleGlass';

const TestResultPrint = ({
  testing,
  diagnose,
  reTestDate,
  prescription = [],
}) => {
  const [indicators, setIndicators] = useState();

  useEffect(() => {
    if (!testing) return;
    setIndicators(parseIndicators(testing.indicators));
  }, [testing]);

  const renderReTestDateInfo = () => {
    if (!reTestDate) return '';
    if (reTestDate.type === RETEST_DATE_TYPE.SELECT && reTestDate.optionValue) {
      const option = RETEST_DATE_OPTIONS.find(
        (op) => op.value === reTestDate.optionValue,
      );

      if (!option) return '';

      return (
        <>
          <b>Ngày khám lại: </b> {dayjs(reTestDate.date).format('DD/MM/YYYY')}
        </>
      );
    }

    return (
      <>
        <b>Ngày khám lại: </b> {dayjs(reTestDate.date).format('DD/MM/YYYY')}
      </>
    );
  };

  return (
    <StyledTestResult>
      <NoGlass indicators={indicators} />
      <SingleGlass indicators={indicators} />
      <div className="text-col">
        <p className="prescription-title">CHẨN ĐOÁN</p>
        <div className="detail">
          <p className="diagnose-item">
            <strong>Hai mắt:</strong>
            <span>{diagnose?.general}</span>
          </p>
          <p className="diagnose-item">
            <strong>Mắt phải:</strong>
            <span>{diagnose?.rightEye}</span>
          </p>
          <p className="diagnose-item">
            <strong>Mắt trái:</strong>
            <span>{diagnose?.leftEye}</span>
          </p>
        </div>
      </div>
      <div className="text-col">
        <p className="prescription-title">ĐƠN THUỐC</p>
        <div className="detail">
          {prescription.map((item) => (
            <p className="diagnose-item" key={item.productId}>
              <strong>{item.name}:</strong>
              <span>{item.instruction}</span>
            </p>
          ))}
        </div>
      </div>
      <div className="note-result">
        Các thuốc tra cách nhau ít nhất 5 phút, thuốc mỡ tra cuối cùng.
      </div>
      <p className="order-note">{renderReTestDateInfo()}</p>
      <p className="order-note">
        <b>Ghi chú</b>: {testing?.note || ''}
      </p>
    </StyledTestResult>
  );
};

export default TestResultPrint;
