import api from './api';

export const statsRevenueByDate = async ({ dateType, startDate, endDate }) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/statistics/revenue-benefit-by-date',
      params: {
        dateType,
        startDate,
        endDate,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export const statsRevenueByProductType = async ({ startDate, endDate }) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/statistics/revenue-by-product-type',
      params: {
        startDate,
        endDate,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export const statsRevenueByOrderStatus = async ({ startDate, endDate }) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/statistics/revenue-by-order-status',
      params: {
        startDate,
        endDate,
      },
    });
    return res;
  } catch (error) {
    return error.response?.data;
  }
};
